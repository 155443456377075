export { dbNames } from './utils';

type requestType = 'GET' | 'POST' | 'PATCH' | 'DELETE';

type Payload = {
  [key: string]: string | number | boolean | object | null;
};

  export const isLocalHost = process.env.NODE_ENV !== 'production'

interface Config {
  getNumber(name: string): number;
  getString(name: string): string;
}

function getHostUrl() {
  let _window: Location | null = null;
  if (typeof window !== 'undefined') {
    _window = window.location;

  }
  if (_window?.hostname === 'localhost') {
    return 'http://localhost:7007';
  }
  return `https://${_window?.hostname}`;
}
export const hostUrl = getHostUrl();

export function removeSpecialCharacters(data: string) {
  const harmfulCharacters = /'|"|;|\\/g; // No @ts-expect-error needed here
  const sanitizedName = data.replace(harmfulCharacters, '');
  let escapedName = sanitizedName.replace(/&/g, '&amp;');
  escapedName = escapedName.replace(/</g, '&lt;');
  escapedName = escapedName.replace(/>/g, '&gt;');
  let doubleEscapedName = escapedName.replace(/&amp;/g, '&amp;amp;');
  doubleEscapedName = doubleEscapedName.replace(/&lt;/g, '&amp;lt;');
  doubleEscapedName = doubleEscapedName.replace(/&gt;/g, '&amp;gt;');
  doubleEscapedName = doubleEscapedName.replace(/>/g, '&gt;');
  doubleEscapedName = doubleEscapedName.replace(/"/g, '&quot;');
  doubleEscapedName = doubleEscapedName.replace(/'/g, '&apos;');
  doubleEscapedName = doubleEscapedName.replace(/&lt;/g, '<');
  doubleEscapedName = doubleEscapedName.replace(/&gt;/g, '>');
  doubleEscapedName = doubleEscapedName.replace(/</g, '&lt;');
  doubleEscapedName = doubleEscapedName.replace(/>/g, '&gt;');
  doubleEscapedName = doubleEscapedName.replace(/"/g, '&quot;');
  doubleEscapedName = doubleEscapedName.replace(/'/g, '&apos;');
  doubleEscapedName = doubleEscapedName.replace(/&amp;/g, '&');
  return doubleEscapedName;
}

export const urls = {
  yamlValidator: `api/catalog/validator?url=`,
  systemsByGroup: `api/catalog/systemsByGroup?name=`,
  getAllSystems: `api/catalog/getAllSystems`,
  getComponentByServiceName: `api/catalog/getComponentByServiceName?name=`,
  getSystemByServiceName: `api/catalog/getSystemByServiceName?name=`,
  getAdrList: `api/adr/list?url=`,
  getAdrContentByName: `api/adr/file?url=`,
  getBase64Image: `api/catalog/getBase64Image?url=`,
  isAzureProjectExists: `api/catalog/checkIfProjectExists?name=`,
  getPipelineDataByTemplateName: `api/catalog/getPipelineParamsByName?name=`,
  prepareGardRailsData: `api/techdocs/sync/default/domain/cop_dso`,
  getGuardRailsData: `api/techdocs/static/docs/default/domain/cop_dso/index.html`,
  getAllAdrs: `api/catalog/getAllAdrs`,
  getEpicAvatarArr: `api/catalog/getAvatarByName?name=`,
  getDevSecOpsEpics: `api/catalog/getEpics`,
  getOwnershipCount: `api/catalog/getOwnershipCount?name=`,
  getSystemsCountByTeam: `api/catalog/getSystemsCountByTeam`,
  getContributorsByTeam: `api/catalog/entities/by-name/group/default/`,
  getTeamsDataById: `api/catalog/entities/by-name/domain/default/`,
  getConfigByName: `api/catalog/getConfigByName?name=`,
  getMultiAdrList: `api/catalog/listMultipleAdr?url=`,
  getPdfFile: `api/catalog/getPdfFile?url=`,
  getAllProcessedOwners: `api/catalog/getAllProcessedOwners`,
  getTrackSpaceUser: `api/catalog/getTrackSpaceUser?user=`,
  getCreatedTrackSpaceId: `api/catalog/getCreatedTrackSpaceId?id=`,
  getPiplineStatusByTasKId: `api/catalog/getPipelineStatus?id=`,
  getAllUsers: `api/catalog/getAllUsers`,
  getAzureLogsById: `api/catalog/getAzureLogsById?logId=`,
  runComponentAzurePipelineStatus: `api/catalog/runComponentAzureDevOps?token=`,
  getFolderPath: `api/catalog/getFolderPathByName`,
  getLanguageById: `api/catalog/getLinguistByURl?id=`,
  getPowerBiReport: `api/catalog/getPowerBiReport?url=`,
  checkIfGroupExists: `api/catalog/checkIfGroupExists?id=`,
  oIPJsonValidateEndpoint: `https://openapi-validator.api-dev.lhgroup.de/api/v1/validate`,
  checkmarxScanResultByProject: `api/catalog/SastReportByProjectName?projectName=`,
  getAllCustomGroupInfo:`api/catalog/getAllCustomGroupInfo`,
  // DAP Feature Flags
  getTeams: `api/dap/teams`,
  createTeams: `api/dap/teams`,
  updateTeam: `api/dap/teams`,
  deleteTeam: `api/dap/teams/`,
  getTeamConfig: `api/dap/config/`,
  setConfig: `api/dap/config/`,
};

export const requestHeader = (type: requestType, payload?: Payload) => {
  const token = (window as any).dhDockStore?.getState()?.auth?.token || null;

  //check if token has expired or about to expire.
  const payloadBase64 = token.split('.')[1];
  const decodedPayload = JSON.parse(atob(payloadBase64));
  const exp = decodedPayload.exp;
  const currentTime = Math.floor(Date.now() / 1000);

  if (exp < currentTime || exp - currentTime < 60 * 5) {
     window.location.reload()
  }

  let requestConfig: RequestInit = {
    method: type,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  // Add body for POST requests
  if ((type === 'POST' || type === 'PATCH') && payload) {
    requestConfig = {
      ...requestConfig,
      body: JSON.stringify(payload),
    };
  }
  return requestConfig;
};

export const getEnv = (config: Config, name: string) => {
  const envtoken = config.getString(name);
  return envtoken;
};

export const getEnvNumber = (config: Config, name: string) => {
  const env_token = config.getNumber(name);
  return env_token;
};

export async function fetchData(
  url: string,
  payload?: Payload,
  isPatch?: boolean,
  isDelete?: boolean,
): Promise<any> {
  try {
    let methodType: requestType = 'GET';

    if (!payload) {
      methodType = 'GET';
    } else if (isPatch) {
      methodType = 'PATCH';
    } else if (isDelete) {
      methodType = 'DELETE';
    } else {
      methodType = 'POST';
    }
    // Fetch the response using the requestHeader
    const responseContent = await fetch(
      `${hostUrl}/${url}`,
      requestHeader(methodType, payload),
    );

    if (responseContent.status === 401) {
      // token expired... remove current token from store so it can render the auth page again
      window.location.reload();
      return Promise.reject('Session expired. Please log in again.');
    }

    const contentType = responseContent.headers.get('Content-Type');

    // Declare a variable for resolved content
    let resolvedContent;

    if (contentType) {
      // Check for JSON content type
      if (contentType.includes('application/json')) {
        resolvedContent = await responseContent.json();
      }
      // Check for PDF content type (return as Blob)
      else if (contentType.includes('application/pdf')) {
        resolvedContent = await responseContent.blob();
      }
      // Handle plain text or other content types
      else {
        resolvedContent = await responseContent.text();
      }
    }

    // Check if the response is successful
    if (responseContent.ok) {
      // Handle PDF separately by creating a Blob URL
      if (contentType && contentType.includes('application/pdf')) {
        return URL.createObjectURL(resolvedContent); // Return Blob URL for PDF display
      }
      return resolvedContent; // Return resolved content (JSON or text)
    }

    // Reject if the response was not successful
    return Promise.reject(resolvedContent);
  } catch (error) {
    // Handle any errors
    if (error instanceof Error) {
      return Promise.reject(error.message);
    }
  }

  return null;
}


