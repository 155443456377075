/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { InfoCard } from '@backstage/core-components';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { fetchData, urls } from '@internal/backstage-plugin-dh-dock-shared-common';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  infoCard: {
    marginBottom: theme.spacing(3),
  },
  barContainer: {
    height: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: '4px',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  bar: {
    height: '100%',
    position: 'relative',
  },
  languageDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    display: 'inline-block',
  },
  label: {
    color: 'inherit',
  },
}));




// Interface for the individual language breakdown
interface LanguageBreakdown {
  name: string;           // Name of the language
  percentage: number;     // Percentage of the total bytes
  bytes: number;          // Byte count for this language
  type: 'programming' | 'data'; // Type of language (either programming or data)
  color: string;          // Hex color associated with the language
}

// Main interface for the entire data structure
interface LanguageData {
  languageCount: number;         // Total number of languages
  totalBytes: number;            // Total byte count
  processedDate: string;         // ISO string representing the processed date
  breakdown: LanguageBreakdown[]; // Array of language breakdowns
}
export const LinguistCard = () => {
  const [language,setLanguage]=useState<LanguageData>();
  const [loading,setIsLoading]=useState<boolean>(true);
  const classes = useStyles();
  const {entity}=useEntity();
  const theme = useTheme();
  let barWidth = 0;
  const url=window.location.pathname.split('/')[4]
  const id=entity.kind.concat(':default/').concat(url as any)


  const getLanguage = useCallback(async () => {
    try {
      setIsLoading(true); // Ensure loading state is set before fetching
      const response = await fetchData(`${urls.getLanguageById}${id.toLowerCase()}`) as any;
      setIsLoading(false);
  
      if (response && Array.isArray(response)) {
        setLanguage(JSON.parse(response[0].languages));
      } else {
        setLanguage(response);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching language:", error);
    }
  }, [id, urls]); // Include dependencies if they change
  
  

  useEffect(()=>{
    getLanguage()
  },[getLanguage])


  if(loading){
    return <LinearProgress/>
  }
  

  if (!language && !loading) {
    return (
      <InfoCard title='Languages' className={classes.infoCard}>
        <Grid container spacing={3}>
          <Box p={2}>
            <Typography>There is no language for this entity</Typography>
          </Box>
        </Grid>
      </InfoCard>
    );
  }

  
  return  (
    <InfoCard title='Languages' className={classes.infoCard}>
      <Box className={classes.barContainer}>
        {language?.breakdown.sort((a,b)=>{
          return b.percentage-a.percentage
        })?.map((_language, index: number) => {
          barWidth = barWidth + _language.percentage;
          return (
            <Tooltip
              title={_language.name}
              placement="bottom-end"
              key={_language.name}
            >
              <Box
                className={classes.bar}
                key={_language.name}
                style={{
                  marginTop: index === 0 ? '0' : `-16px`,
                  zIndex: Object.keys(language.breakdown).length - index,
                  backgroundColor:
                  _language.color?.toString() ||
                    theme.palette.background.default,
                  width: `${barWidth}%`,
                }}
              />
            </Tooltip>
          );
        })}
      </Box>
      <Tooltip
         title={`Generated ${DateTime.fromISO(language?.processedDate!!).toRelative()}`}
      >
        <Box>
          {language?.breakdown?.sort((a,b)=>{
            return b.percentage-a.percentage
          }).map(languages => (
            <Chip
              classes={{
                label: classes.label,
              }}
              label={
                <Box>
                  <Box
                    component="span"
                    className={classes.languageDot}
                    style={{
                      backgroundColor:
                        languages?.color?.toString() ||
                        theme.palette.background.default,
                    }}
                  />
                  {languages.name} - {languages.percentage}%
                </Box>
              }
              variant="outlined"
              key={languages.name}
            />
          ))}
        </Box>
      </Tooltip>
    </InfoCard>
  ) 
};