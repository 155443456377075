import { Link, Progress } from '@backstage/core-components';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

import { InfoPage } from '../../components/ui/Notifications';
import { useAppSelector } from '../../hooks/reduxhooks';
import { useEntity } from '@backstage/plugin-catalog-react';

const ownershipTypes = ['system', 'api', 'component'] as const;

interface ownershipTeamsProps {
  kind: (typeof ownershipTypes)[number];
  entity_ref: string;
  api_count: number;
  system_count: number;
  component_count: number;
  owners: string;
}

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
      padding: theme.spacing(2),
      transition: `${theme.transitions.duration.standard}ms`,
      '&:hover': {
        boxShadow: theme.shadows[4],
      },
      height: '100%',
    },
    smallFont: {
      fontSize: theme.typography.body2.fontSize,
    },
    entityTypeBox: {
      background: (props: { type: string }) =>
        theme.getPageTheme({ themeId: props.type }).backgroundImage,
      color: (props: { type: string }) =>
        theme.getPageTheme({ themeId: props.type }).fontColor,
    },
  }),
);

const EntityCountTile = ({
  counter,
  type,
  kind,
  url,
}: {
  counter: number;
  type?: string;
  kind: string;
  url: string;
}) => {
  const classes = useStyles({ type: type ?? kind });
  const rawTitle = type ?? kind;
  const isLongText = rawTitle?.length > 10;

  return (
    <Link to={url} variant="body2">
      <Box
        className={`${classes.card} ${classes.entityTypeBox}`}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h6">{counter}</Typography>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography
            className={`${isLongText && classes.smallFont}`}
            variant="h6"
          />
        </Box>
        <Typography variant="subtitle1">{kind}</Typography>
      </Box>
    </Link>
  );
};

export const ComponentsGrid = () => {
  const group = window.location.pathname.split('/')[4];

  const { owners, loading } = useAppSelector(state => state.ownersProps);

  const constructUrl = (c: ownershipTeamsProps, groups_: string) => {
    let modifiedGroup = groups_.trim();
    const newArr = modifiedGroup
      .split(',')
      .map(item =>
        'group%3Adefault%2F'.concat(item).concat('&filters%5Bowners%5D='),
      );
    newArr[0] = '&filters%5Bowners%5D='.concat(newArr[0]);
    let baseUrl = '';
    if (groups_.includes(',')) {
      baseUrl = `/catalog/?filters%5Bkind%5D=${c.kind}${newArr.join(',').replaceAll(',', '')}&filters%5Buser%5D=all&mainowner=${group}`;
    } else {
      baseUrl = `/catalog/?filters%5Bkind%5D=${c.kind}&filters%5Bowners%5D=${c.owners.includes(' ') ? c.owners.concat(`&filters%5Bowners%5D=${group}`) : c.owners}&filters%5Buser%5D=all&mainowner=${group}`;
    }

    if (c.kind === 'system') {
      return baseUrl.replace('/catalog/?', '/catalog?');
    }
    return baseUrl;
  };

  if (loading) {
    return <Progress />;
  }

  const getDistinctOwners = (data: ownershipTeamsProps[]) => {
    const ownersSet = new Set<string>();

    data.forEach(item => {
      if (item.owners?.includes(','))
        item.owners.split(',').forEach(owner => ownersSet.add(owner.trim()));
      else ownersSet.add(item.owners.trim());
    });

    return Array.from(ownersSet);
  };

  let groupedData, allOwnersMatchingGroup;
  const { entity } = useEntity();

  if (entity.kind.toLowerCase() === 'user') {
    // get all the groups the user belongs to
    const groups = entity.relations
      ?.filter(x => x.type === 'memberOf')
      ?.map(group_ => group_?.targetRef.split('/')[1].toLowerCase());

    allOwnersMatchingGroup = owners?.filter(owner => {
      return groups?.some(group_ => {
        return (
          group_.toLowerCase() === owner?.owners?.toLowerCase() ||
          group_.toLowerCase() === owner?.owners?.toLowerCase().split('/')[1]
        );
      }); // Check if it matches any group
    });
  } else {
    allOwnersMatchingGroup = owners?.filter(x =>
      x?.owners.toLowerCase().includes('/')
        ? x?.owners.toLowerCase().split('/')[1] === group.toLowerCase()
        : x?.owners.toLowerCase() === group.toLowerCase() ||
          x?.owners
            ?.split(' ')
            .map((item: string) => item.trim().toLowerCase())
            .some((item: string) =>
              item.includes('/')
                ? item.split('/')[1]
                : item.toLowerCase() === group.toLowerCase(),
            ),
    );
  }

  try {
    groupedData =
      allOwnersMatchingGroup?.length > 0 &&
      allOwnersMatchingGroup.reduce(
        (acc, item) => {
          const totalCount =
            item.api_count + item.component_count + item.system_count;
          // Normalize item.owners and check if group is included (case insensitive)
          // Classify the item based on its 'kind'
          if (item?.kind === 'system') {
            acc.system.items.push(item);
            acc.system.totalCount += totalCount;
          } else if (item?.kind === 'api') {
            acc.api.items.push(item);
            acc.api.totalCount += totalCount;
          } else if (item?.kind === 'component') {
            acc.component.items.push(item);
            acc.component.totalCount += totalCount;
          }

          // Always return the accumulator
          return acc;
        },
        {
          system: { items: [], totalCount: 0 },
          api: { items: [], totalCount: 0 },
          component: { items: [], totalCount: 0 },
        },
      );
  } catch (error) {
    console.error('An error occurred during data grouping:', error);
    groupedData = {
      system: { items: [], totalCount: 0 },
      api: { items: [], totalCount: 0 },
      component: { items: [], totalCount: 0 },
    };
  }

  // Create result array directly from grouped data
  const resultArray = [
    { kind: 'system', ...groupedData.system },
    { kind: 'api', ...groupedData.api },
    { kind: 'component', ...groupedData.component },
  ];

  return (
    <Grid container spacing={2}>
      {owners?.length > 0 && resultArray?.length > 0 ? (
        resultArray.map(c => {
          if (c?.items?.length > 0) {
            
            const distinctGroups = getDistinctOwners(allOwnersMatchingGroup);
            const url = constructUrl(c?.items[0], distinctGroups.join(','));
            return (
              <Grid item xs={6} md={6} lg={4} key={c?.kind}>
                <EntityCountTile
                  counter={c?.totalCount}
                  kind={
                    c?.kind?.toLowerCase() === 'system'
                      ? 'PRODUCT(S)'
                      : c?.kind?.concat('(S)').toUpperCase()
                  }
                  type={c?.kind.toUpperCase()}
                  url={url}
                />
              </Grid>
            );
          }
          return null;
        })
      ) : (
        <InfoPage type="info" message="No owned entities" />
      )}
    </Grid>
  );
};
