
import styles from "./QuadrantLink.module.css";

import Pie from "../Icons/Pie";
import { getLabel } from "../../../utils/radar/data";
import { Quadrant } from "../../../utils/radar/types";
import { cn } from "../../../utils";
import React from "react";
import { techRaderProps } from "packages/app/src/utils/radar/data/types";
import { useNavigate } from "react-router-dom";

interface QuadrantLinkProps extends techRaderProps {
  quadrant: Quadrant;
  label?: string;
  className?: string;
}
export function QuadrantLink({
  quadrant,
  label = getLabel("zoomIn"),
  className,
}: QuadrantLinkProps) {

  const navigate=useNavigate();
  const handleNavigate=()=>{
   navigate(`/techRadar/${quadrant.title.toLowerCase()}`, { replace: true });

  }
  return (
    <div 
  className={cn(styles.link, className)} 
  onClick={handleNavigate} 
  onKeyDown={(e) => {
    if (e.key === "Enter" || e.key === " ") {
      handleNavigate();
    }
  }}
  role="button"
  tabIndex={0}
>
      <Pie className={styles.icon} />
      <span className={styles.label}>{label}</span>
    </div>
  );
}
