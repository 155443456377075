import React from "react";
import { useParams } from "react-router-dom";
import { getQuadrant } from "../../utils/radar/data";
import { useAppSelector } from "../../hooks/reduxhooks";
import { QuadrantList } from "../../components/techRadarSpike/QuadrantList/QuadrantList";
import { LinearProgress } from "@material-ui/core";
import { Header } from "@backstage/core-components";


const QuadrantOverview=()=>{
  const {quadrant}=useParams();
  const {techRadarArr,loading}=useAppSelector((state)=>state.techRadar)
  const quadrantData=getQuadrant(quadrant as string);
  const technologies=techRadarArr.filter(x=>x.quadrant.toLowerCase()===quadrantData?.id.toLowerCase())
  if(loading){
    return <LinearProgress/>
  }
  return (
    <>
   <Header title={quadrantData?.title}  />
    <div style={{ width: '80%', margin: '10px auto' }}>
      <QuadrantList items={technologies} type="overview" description={quadrantData?.description} />
    </div>
    </>

  );
  
}

export default QuadrantOverview;