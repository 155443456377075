import { Alert, AlertTitle } from "@material-ui/lab"
import { Snackbar } from "@mui/material";
import React from "react";

interface messageProps{
  type:'info'|'success'|'error';
  message:string
}
export function ErrorPage({message,type}:messageProps){
  return (
    <Alert severity={type}>
      <AlertTitle>An error occured</AlertTitle>
      <strong>{message}</strong>
    </Alert>
  ); 
}
export function InfoPage({message,type}:messageProps){
  return (
    <Alert severity={type}>
      <strong>{message}</strong>
    </Alert>
  ); 
}

export function SnackBar({message,type}:messageProps){
  return (
    <Snackbar open={true} autoHideDuration={5000}  anchorOrigin={{
      vertical: "top",  // Centers the snackbar vertically
      horizontal: "center",  // Centers the snackbar horizontally
    }}>
    <Alert  severity={type} variant="filled">
      {message}
    </Alert>
  </Snackbar>
  )
}