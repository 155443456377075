import styles from "./ItemDetail.module.css";

import { RingBadge } from "../Badge/Badge";
import { Attention, Edit } from "../Icons";
import { Tag } from "../Tags/Tags";
import { getLabel, getReleases } from "../../../utils/radar/data";
import { Item } from "../../../utils/radar/types";
import { cn } from "../../../utils";
import React from "react";

const latestReleases = getReleases().slice(-3);

function isNotMaintained(release: string) {
  return !latestReleases.includes(release);
}

interface ItemProps {
  item: Item;
  editLink:string
}

export function ItemDetail({ item,editLink }: ItemProps) {
  const notMaintainedText = getLabel("notUpdated");
  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.title}>{item.title}</h1>
        {item.tags?.map((tag) => <Tag key={tag} tag={tag} to={""} />)}
      </div>
      <div className={styles.revisions}>
        {notMaintainedText && isNotMaintained(item.release) && (
          <div className={cn(styles.revision, styles.hint)}>
            <span className={styles.release}>
              <Attention className={styles.notMaintainedIcon} />
            </span>
            <div className={styles.content}>{notMaintainedText}</div>
          </div>
        )}
        <Revision
          id={item.id}
          release={item.release}
          ring={item.ring}
          body={item?.description??item?.body}
          editLink={editLink}
        />
        {item.revisions?.map((revision, index) => (
          <Revision key={index} id={item.id} {...revision} />
        ))}
      </div>
    </>
  );
}

interface RevisionProps {
  id?: string;
  release: string;
  ring: string;
  body?: string;
  editLink?:string
}

function Revision({  release, ring, body,editLink }: RevisionProps) {
  const date = new Date(release);
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
  });
  return (
    <div className={cn(styles.revision, !body && styles.noContent)}>
      <time dateTime={release} className={styles.release}>
        {formattedDate}
      </time>
      <div className={cn(styles.content)} style={{borderRadius: '10px',borderWidth: '1px',borderStyle: 'groove'}}>
        <RingBadge className={styles.ring} ring={ring} size="large" />
        {body ? <div dangerouslySetInnerHTML={{ __html: body }} /> : null}
        {editLink && (
          <a href={editLink} target="_blank" className={styles.editLink}>
            <Edit />
          </a>
        )}
      </div>
    </div>
  );
}
