import { Content, Header, Page } from '@backstage/core-components';
import SupportIcon from '@material-ui/icons/ContactSupport'
import React, { FC} from 'react';

import './Reports.css';


const Report: FC<{ url?: string }> = ({ url }) => {
  
  return (
    <>
      {!url ? (
        <Page themeId="tool">
          <Header title="Report" subtitle='Report to show compliance of teams according to guardrails' />

          <Content>
            <section
              style={{
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'flex-end',
              }}
              onClick={() => {
                return (window.location.href =
                  '/docs/default/System/DH-Dock/guides/how-to-access-reports/');
              }}
            >
              <SupportIcon /> SUPPORT
            </section>
            <div className="responsive-iframe-container">
              <iframe
                src={
                  url ??
                  'https://app.powerbi.com/reportEmbed?reportId=8c363a13-0380-431e-88e5-f37cd3a4160e&autoAuth=true&ctid=72e15514-5be9-46a8-8b0b-af9b1b77b3b8'
                }
              />
            </div>
          </Content>
        </Page>
      ) : (
        <div
          className="responsive-iframe-container"
          style={{ marginTop: '70px' }}
        >
          <iframe src={url} />
        </div>
      )}
    </>
  );
};

export default Report;
