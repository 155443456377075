interface Item {
  id: string;
  ring: string;
  flag: string;
  title: string;
  quadrant: string;
  featured: boolean;
  body: string;
  tags: string[];
  release: string;
  position?: [number, number]; 
  description: string;
}

interface Quadrant {
  id: string;
  position: number;
  color: string;
}

interface Ring {
  id: string;
  title: string;
  description: string;
  color: string;
  radius: number;
  strokeWidth: number;
}

interface Position {
  x: number;
  y: number;
}
export const calculateItemPositions = (
  items: Item[],
  quadrants: Quadrant[],
  rings: Ring[],
  size: number
): Item[] => {
  const center = size / 2;
  
  const startAngles: Record<number, number> = { 
    1: 180,  
    2: 270,  
    3: 90,   
    4: 0    
  };
  const maxAttempts = 100;
  const placedPositions: Position[] = [];

  // Helper function to get ring index based on array position
  const getRingIndex = (ringId: string) => {
    return rings.findIndex((r) => r.id === ringId);
  };

  
  
  const getRandomPosition = (quadrant: Quadrant, ring: Ring): Position => {
    const angleStart = startAngles[quadrant.position];
    const angleEnd = angleStart + 90;
    const angle = Math.random() * (angleEnd - angleStart) + angleStart;
  
    const ringIndex = getRingIndex(ring.id);
   
    const innerRadius = ringIndex > 0 ? (rings[ringIndex - 1]?.radius || 0) * center + 5 : 0;  
    const outerRadius = ring.radius * center - 5;  
  
    const radius = Math.random() * (outerRadius - innerRadius) + innerRadius;
  
    return {
      x: Math.round(center + radius * Math.cos((angle * Math.PI) / 180)),
      y: Math.round(center + radius * Math.sin((angle * Math.PI) / 180)),
    };
  };
  
  const isCollision = (x: number, y: number): boolean => {
    return placedPositions.some((pos) => {
      const dx = pos.x - x;
      const dy = pos.y - y;
      return Math.sqrt(dx * dx + dy * dy) < 20;
    });
  };

  return items.map((item) => {
    const ring = rings.find((r) => r.id === item.ring);
    const quadrant = quadrants.find((q) => q.id === item.quadrant);
    if (!ring || !quadrant) return item;

    let position: Position;
    let attempts = 0;
    do {
      position = getRandomPosition(quadrant, ring);
      attempts++;
    } while (isCollision(position.x, position.y) && attempts < maxAttempts);

    placedPositions.push(position);
    return { ...item, newposition: [position.x, position.y] };  // Use newposition instead of position
  });
};
