import {
  CatalogIcon,
  Content,
  DocsIcon,
  Header,
  Page,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import {
  DefaultResultListItem,
  SearchBar,
  SearchFilter,
  SearchPagination,
  SearchResult,
  useSearch,
} from '@backstage/plugin-search-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getAdrListByUrl, getAllAdrs } from '../../services/adr.service';
import AdrList from './AdrSearch/AdrList';
import SearchTypeAccordion from './SearchTypeAccordion';
import { SnackBar } from '../ui/Notifications';

export interface adrProps {
  url: string;
  link: string;
  data?: [
    {
      date: string;
      path: string;
      status: string;
      title: string;
      type: string;
      tag: string;
    },
  ];
}

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
}));
const getAllAdrsList = async () => {
  const response = await getAllAdrs();
  return response;
};

const SearchPage = () => {
  const classes = useStyles();
  const { types } = useSearch();
  const catalogApi = useApi(catalogApiRef);

  const [adrSearchList, setAdrSearchList] = useState<any[]>([]);
  const [adrSearchListCopy, setAdrSearchListCopy] = useState<any[]>([]);
  const [selected, setSelected] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isResultEmpty,setIsResultEmpty]=useState(false)
  const hasDataRef = useRef(false);

  const getAllAdrsData = useCallback(async () => {
    try {
      const Adrs = (await getAllAdrsList()) as adrProps[];
      if (Adrs && Adrs.length > 0) {
        const fetchAllAdrs: adrProps[] = await Promise.all(
          Adrs.filter(x => x.url && x.url.startsWith('https://'))?.map(
            async (item: any) => {
              try {
                const response = await getAdrListByUrl(item?.url);
                response.url = item;
                return response;
              } catch (error) {
                if (error instanceof Error) {
                  console.log(error.message);
                }
                return null;
              }
            },
          ),
        );

        if (fetchAllAdrs.length > 0) {
          fetchAllAdrs
            .filter(x => x?.data)
            .forEach((item_: any) => {
              item_.data.forEach((item: any) => {
                if (
                  item?.path?.endsWith('.md') ||
                  item?.path?.endsWith('.MD')
                ) {
                  setAdrSearchList(adrList => [
                    ...adrList,
                    {
                      path: item,
                      link: item_?.url?.link,
                      otherContent: item?.content.join(','),
                    },
                  ]);
                  setAdrSearchListCopy(adrList => [
                    ...adrList,
                    {
                      path: item,
                      link: item_?.url?.link,
                      otherContent: item?.content.join(','),
                    },
                  ]);
                }
              });
            });
        }
      }

      hasDataRef.current = true;
      return null; // Explicit return
    } catch (err) {
      console.error(err);
      return null; // Explicit return in case of an error
    }
  }, []);

  useEffect(() => {
    getAllAdrsData();
  }, [getAllAdrsData]);

  const handleSearch = (value: string) => {
    if (value && value.trim()) {
      setAdrSearchList([]);
      const searchResult = [...adrSearchListCopy];
      const response = searchResult.filter(x => x.otherContent.includes(value));
      setAdrSearchList(response);
      setIsResultEmpty(false)
    } else {
      setIsResultEmpty(true)
      setAdrSearchList([]);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
  
    setSelectedTags((prevSelected) => {
      let updatedTags = checked
        ? [...new Set([...prevSelected, value])] // Ensure uniqueness
        : prevSelected.filter((tag) => tag !== value); // Remove unchecked tag
      // Handle adrSearchList filtering based on updated tags
      if (updatedTags.length > 0) {
        const filteredResults = adrSearchListCopy.filter((x) =>
          updatedTags.some((tag) => x.path?.tag?.toLowerCase().includes(tag.toLowerCase()))
        );
        if(filteredResults.length>0){
          setIsResultEmpty(false)
          setAdrSearchList(filteredResults);

        }
      else
        setIsResultEmpty(true)
      } else {
        // Restore full list if no checkboxes are selected
        setAdrSearchList(adrSearchListCopy);
        setIsResultEmpty(false)
      }
  
      return updatedTags;
    });
  };
  

  return (
    <Page themeId="home">
      <Header title="Search" />
      <Content>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Paper className={classes.bar}>
              {selected === 'adr' ? (
                <SearchBar onChange={event => handleSearch(event)} />
              ) : (
                <SearchBar />
              )}
            </Paper>
          </Grid>
          <Grid item xs={3} style={{}}>
            <SearchTypeAccordion
              onSelect={setSelected}
              name="Result Type"
              defaultValue="software-catalog"
              types={[
                {
                  value: 'all',
                  name: 'All',
                  icon: <VisibilityIcon />,
                },
                {
                  value: 'software-catalog',
                  name: 'Software Catalog',
                  icon: <CatalogIcon />,
                },

                {
                  value: 'adr',
                  name: 'Architecture Decision Records',
                  icon: <DocsIcon />,
                },
              ]}
            />
            <Paper className={classes.filters}>
              {types.includes('techdocs') && (
                <SearchFilter.Select
                  className={classes.filter}
                  label="Entity"
                  name="name"
                  values={async () => {
                    // Return a list of entities which are documented.
                    const { items } = await catalogApi.getEntities({
                      fields: ['metadata.name'],
                      filter: {
                        'metadata.annotations.backstage.io/techdocs-ref':
                          CATALOG_FILTER_EXISTS,
                      },
                    });

                    return items
                      .map(entity => entity.metadata.name)
                      .sort((a: any, b: any) => a - b);
                  }}
                />
              )}
              {selected !== 'adr' && (
                <SearchFilter.Select
                  className={classes.filter}
                  label="Kind"
                  name="kind"
                  values={['Component', 'Template']}
                />
              )}
              {selected === 'adr' && (
                <>
                  <Typography><b>Tags</b></Typography>
                  <FormControl component="fieldset">
                    <FormGroup>
                      {[
                        'Backend',
                        'FrontEnd',
                        'Mobile',
                        'Security',
                        'DevOps',
                        'Artificial Intelligence / Gen AI'
                      ].map(tag => (
                        <FormControlLabel
                          key={tag}
                          control={
                            <Checkbox
                              checked={selectedTags.includes(tag)}
                              onChange={handleCheckboxChange}
                              value={tag}
                              color="primary"
                            />
                          }
                          label={tag}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </>
              )}

              {selected !== 'adr' && (
                <SearchFilter.Checkbox
                  className={classes.filter}
                  label="Lifecycle"
                  name="lifecycle"
                  values={['experimental', 'production']}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={9}>
            {!window.location.href?.endsWith('adr') && <SearchPagination />}
            <SearchResult>
              {({ results }) => (
                <List>
                  <>
                    {selected !== 'adr' &&
                      results.map(({ type, document, highlight, rank }) => {
                        switch (type) {
                          case 'software-catalog':
                            return (
                              <CatalogSearchResultListItem
                                key={document.location}
                                result={document}
                                highlight={highlight}
                                rank={rank}
                              />
                            );
                          case 'techdocs':
                            return (
                              <TechDocsSearchResultListItem
                                key={document.location}
                                result={document}
                                highlight={highlight}
                                rank={rank}
                              />
                            );

                          default:
                            return (
                              <DefaultResultListItem
                                key={document.location}
                                result={document}
                                highlight={highlight}
                                rank={rank}
                              />
                            );
                        }
                      })}
                    {selected === 'adr' && (
                      <AdrList
                        result={adrSearchList?.filter((item, index, self) => {
                          return (
                            self.findIndex(
                              item_ => item_.path.path === item.path.path,
                            ) === index
                          );
                        })}
                        // Not required if you're leveraging the new search results extensions available in v1.11+
                        // https://backstage.io/docs/features/search/how-to-guides#2-using-an-extension-in-your-backstage-app
                      />
                    )}
                  </>
                </List>
              )}
            </SearchResult>
              {isResultEmpty && <SnackBar message='No results found' type='error' />}

          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export const searchPage = <SearchPage />;
