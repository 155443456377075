/* eslint-disable */

// ESLint will ignore this entire file

import React, { Fragment, useState } from 'react';
import { AddFieldComponent } from '../AddFieldComponent';
import { EditableTypeComponent } from '../EditableTypeComponent';

import { DisplayTypeComponent } from '../EditableTypeComponent/EditableTypeComponent';
import {
  Override,
  TreeParam,
  generateCombinations,
  generateFinalValues,
  getOverride,
  getOverrideColor,
  getOverrideName,
  isAnyOverrided,
} from '../TreeParamComponent/TreeParamComponent';
import { TextEditComponent } from '../TextEditComponent';

export enum JSONType {
  string,
  number,
  boolean,
  stringArray,
  object,
}

export function jsonEnumReplacer(this: any, key: string, value: any): any {
  switch (key) {
    case 'type':
      return JSONType[value];
    default:
      return value;
  }
}

const addNewField = (id: string, name: string, type: JSONType): NodeData => {
  let newNodeData: NodeData = {
    id: id,
    name: name,
    type: type,
  };
  if (type == JSONType.object) {
    newNodeData.children = [];
    newNodeData.showChildren = true;
  }
  return newNodeData;
};

export type NodeData = {
  id: string;
  name: string;
  type: JSONType;
  children?: NodeData[]; // if type is JSONType.object
  showChildren?: boolean;
};

export type Value = {
  key: string;
  type: JSONType;
  defaultValue: string | number | boolean | string[];
  overrides: { [key: string]: string | number | boolean | string[] } | null;
};

export type FeatureData = {
  nodeDatas: NodeData[];
  treeParam: TreeParam;
  values: Value[];
};

interface TreeNodeProps {
  editMode: boolean;
  nodeData: NodeData;
  treeParam: TreeParam;
  siblings: string[];
  nextFreeId: string;
  selectedElement: { key: string; type: JSONType } | null;
  parentKey: string;
  addNewNodeData: (newNodeData: NodeData) => void;
  updateNodeData: (
    idToUpdate: string,
    newNodeData: NodeData,
    needsSaving: boolean,
  ) => void;
  deleteNodeData: (idToDelete: string) => void;
  setSelectedElement: (
    selectedElement: {
      key: string;
      type: JSONType;
    } | null,
  ) => void;
}

const TreeNodeComponent = ({
  nodeData,
  treeParam,
  editMode,
  siblings,
  nextFreeId,
  selectedElement,
  parentKey,
  addNewNodeData,
  updateNodeData,
  deleteNodeData,
  setSelectedElement,
}: TreeNodeProps) => {
  const updateNode = (element: NodeData, needsSaving: boolean = true) => {
    updateNodeData(nodeData.id, element, needsSaving);
  };

  const handleExpand = (element: NodeData) => {
    updateNode({ ...element, showChildren: !element.showChildren }, false);
  };

  const handleTypeChange = (element: NodeData, type: JSONType) => {
    // Changed from object to something else, let's remove the children
    element = { ...element };
    if (element.type == JSONType.object && type != JSONType.object) {
      element.children = undefined;
      element.showChildren = undefined;
    }
    if (type == JSONType.object) {
      element.children = [];
      element.showChildren = true;
    }
    element.type = type;
    updateNode(element);
  };

  const renameElement = (element: NodeData, name: string) => {
    updateNode({ ...element, name: name });
  };

  const appendKeyToParent = (
    parent: string,
    value: string,
    delimiter: string = '.',
  ): string => {
    return parent === '' ? value : parent + delimiter + value;
  };

  return (
    <>
      <tr>
        <td style={{ paddingRight: '10px' }}>
          <span
            style={{
              padding: '0 4px',
              whiteSpace: 'nowrap',
            }}
          >
            {editMode ? (
              <TextEditComponent
                unavailableNames={siblings}
                currentName={nodeData.name}
                onNameChange={name => {
                  renameElement(nodeData, name);
                }}
                onDelete={() => deleteNodeData(nodeData.id)}
              />
            ) : (
              nodeData.name
            )}
          </span>
          {nodeData.type != JSONType.object && (
            <span
              onClick={() =>
                nodeData.type != JSONType.object &&
                setSelectedElement({
                  key: appendKeyToParent(parentKey, nodeData.name),
                  type: nodeData.type,
                })
              }
              style={{
                cursor: 'pointer',
              }}
            >
              {selectedElement &&
              selectedElement.key == appendKeyToParent(parentKey, nodeData.name)
                ? '☑'
                : '☐'}
            </span>
          )}
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {nodeData.type == JSONType.object && (
            <span
              onClick={() => handleExpand(nodeData)}
              style={{
                cursor: 'pointer',
                userSelect: 'none',
                fontWeight: 'bold',
              }}
            >
              {nodeData.showChildren ? '[ - ] ' : '[ + ] '}
            </span>
          )}
          {editMode ? (
            <EditableTypeComponent
              type={nodeData.type}
              onTypeChange={type => handleTypeChange(nodeData, type)}
            />
          ) : (
            <DisplayTypeComponent type={nodeData.type} />
          )}
        </td>
      </tr>
      {nodeData.type == JSONType.object && (
        <tr>
          <td></td>
          <td>
            <table>
              <tbody>
                {nodeData.children &&
                  nodeData.showChildren &&
                  nodeData.children.map((child, index) => {
                    return (
                      <Fragment key={'fragment-TreeNodeComponent' + index}>
                        <TreeNodeComponent
                          key={nodeData.id + index}
                          nodeData={child}
                          treeParam={treeParam}
                          editMode={editMode}
                          siblings={nodeData
                            .children!.filter(item => item.id != nodeData.id)
                            .map(item => item.name)}
                          nextFreeId={nextFreeId + '.' + index}
                          selectedElement={selectedElement}
                          parentKey={appendKeyToParent(
                            parentKey,
                            nodeData.name,
                          )}
                          addNewNodeData={addNewNodeData}
                          updateNodeData={updateNodeData}
                          deleteNodeData={deleteNodeData}
                          setSelectedElement={setSelectedElement}
                        />
                        {editMode &&
                          (nodeData.children?.length ?? 1) - 1 == index && (
                            <tr>
                              <td colSpan={2}>
                                <AddFieldComponent
                                  key={'addField' + nextFreeId}
                                  unavailableNames={siblings}
                                  onNewFieldType={(name, type) =>
                                    addNewNodeData(
                                      addNewField(
                                        nextFreeId +
                                          '.' +
                                          nodeData.children?.length || '0',
                                        name,
                                        type,
                                      ),
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          )}
                      </Fragment>
                    );
                  })}
                {nodeData.children?.length == 0 && nodeData.showChildren && (
                  <AddFieldComponent
                    key={'addField' + nextFreeId}
                    unavailableNames={siblings}
                    onNewFieldType={(name, type) =>
                      addNewNodeData(
                        addNewField(
                          nextFreeId + '.' + nodeData.children?.length || '0',
                          name,
                          type,
                        ),
                      )
                    }
                  />
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

interface TreeTypeProps {
  nodeDatas: NodeData[];
  treeParam: TreeParam;
  values: Value[];
  isEditMode: boolean;
  selectedElement: { key: string; type: JSONType } | null;
  addNewNodeData: (newNodeData: NodeData) => void;
  updateNodeData: (
    idToUpdate: string,
    newNodeData: NodeData,
    needsSaving: boolean,
  ) => void;
  deleteNodeData: (idToDelete: string) => void;
  updateKeyValue: (field: string, key: string, value: string) => void;
  updateSelectedElement: (
    selectedElement: {
      key: string;
      type: JSONType;
    } | null,
  ) => void;
}

export const TreeTypeComponent = ({
  nodeDatas,
  treeParam,
  values,
  isEditMode,
  selectedElement,
  addNewNodeData,
  updateNodeData,
  deleteNodeData,
  updateKeyValue,
  updateSelectedElement,
}: TreeTypeProps) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const hideParams = queryParameters.get('hideParams')?.split(',');
  const showOnlyKeys = queryParameters.get('showOnlyKeys')?.split(',');
  const isDebug = queryParameters.get('debug') != null;
  const hideTreeNode = queryParameters.get('hideTreeNode') != null;

  const [isExpanded, setIsExpanded] = useState(
    treeParam.overrideOrder.map(_ => true),
  );

  const selectElement = (
    selectedElement: { key: string; type: JSONType } | null,
  ) => {
    selectedElement &&
      setIsExpanded(
        treeParam.overrideOrder.map(override => {
          return isAnyOverrided(
            values.find(value => value.key == selectedElement.key)!,
            generateCombinations(treeParam.parameters, override),
          );
        }),
      );
    updateSelectedElement(selectedElement);
  };

  const shouldKeyBeShown = (key: string): boolean => {
    return (
      showOnlyKeys == undefined ||
      key.split('_').some(keyPart => showOnlyKeys?.includes(keyPart))
    );
  };

  const atLeastOneKeyExists = (override: Override): boolean => {
    function anyMatch(override: Override): boolean {
      if (override == undefined) return false;
      return (
        treeParam.parameters
          .find(param => param.name == override.name)!
          .values.some(value => showOnlyKeys?.includes(value)) ||
        (override.child != undefined && atLeastOneKeyExists(override.child))
      );
    }

    return showOnlyKeys == undefined || anyMatch(override);
  };

  const showOrHideAll = (value: boolean) => {
    function showHideAll(nodeData: NodeData, value: boolean) {
      if (nodeData.children) {
        nodeData.showChildren = value;
        updateNodeData(nodeData.id, nodeData, false);
        nodeData.children.forEach(child => showHideAll(child, value));
      }
    }
    nodeDatas.forEach(obj => showHideAll(obj, value));
  };

  return (
    <>
      {hideTreeNode == false && (
        <div style={{ paddingBottom: '4px' }}>
          <button onClick={() => showOrHideAll(true)}>[ + ] Expand All</button>
          <button onClick={() => showOrHideAll(false)}>
            [ - ] Collaspe All
          </button>
        </div>
      )}
      <div style={{ display: 'flex' }}>
        {hideTreeNode == false && (
          <div>
            <table style={{ border: '1px solid black' }}>
              <tbody>
                {nodeDatas.map((node, index) => {
                  return (
                    <TreeNodeComponent
                      key={node.name + index}
                      nodeData={node}
                      treeParam={treeParam}
                      editMode={isEditMode}
                      siblings={nodeDatas
                        .filter(item => item.id != node.id)
                        .map(item => item.name)}
                      nextFreeId={index.toString()}
                      selectedElement={selectedElement}
                      parentKey=""
                      addNewNodeData={addNewNodeData}
                      updateNodeData={updateNodeData}
                      deleteNodeData={deleteNodeData}
                      setSelectedElement={selectElement}
                    />
                  );
                })}
                {isEditMode && (
                  <tr>
                    <td colSpan={2}>
                      <AddFieldComponent
                        key={'addField'}
                        unavailableNames={nodeDatas.map(item => item.name)}
                        onNewFieldType={(name, type) =>
                          addNewNodeData(
                            addNewField(
                              nodeDatas.length.toString(),
                              name,
                              type,
                            ),
                          )
                        }
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        {isDebug && (
          <div
            style={{
              marginLeft: '10px',
              width: '300px',
            }}
          >
            <textarea
              style={{ height: '100%', width: '100%' }}
              readOnly={true}
              value={JSON.stringify(nodeDatas, jsonEnumReplacer, 2)}
            ></textarea>
          </div>
        )}
        <div
          style={{
            marginLeft: '10px',
            border: '1px solid black',
            padding: '10px',
          }}
        >
          <h3>
            {selectedElement ? (
              <span>
                Key: {selectedElement.key}{' '}
                <DisplayTypeComponent
                  key={'selectedKey-' + selectedElement.key}
                  type={selectedElement.type}
                />
              </span>
            ) : (
              'Select an element'
            )}
          </h3>
          {selectedElement &&
            (treeParam.overrideOrder.length == 0 ? (
              <table>
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  Please define the Parameters & Override Order first
                </span>
              </table>
            ) : (
              <table border={1}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: '2px 5px',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      (0) Default
                    </td>

                    {treeParam.overrideOrder.map((override, index) => {
                      if (
                        !hideParams?.includes(index.toString()) &&
                        atLeastOneKeyExists(override)
                      ) {
                        return (
                          <td
                            key={'title' + override.name + index}
                            style={{
                              padding: '2px 5px',
                              backgroundColor: isAnyOverrided(
                                values.find(
                                  value => value.key == selectedElement.key,
                                )!,
                                generateCombinations(
                                  treeParam.parameters,
                                  override,
                                ),
                              )
                                ? 'palegoldenrod'
                                : 'transparent',
                              cursor: 'pointer',
                              userSelect: 'none',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() => {
                              isExpanded[index] = !isExpanded[index];
                              setIsExpanded([...isExpanded]);
                            }}
                          >
                            <span style={{ marginRight: '16px' }}>
                              ({index + 1}) {getOverrideName(override)}
                            </span>
                            <span
                              style={{
                                position: 'relative',
                                right: '0px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {isExpanded[index] ? '[-]' : '[+]'}
                            </span>
                          </td>
                        );
                      }
                      return '';
                    })}

                    <td style={{ padding: '2px 5px', fontWeight: 'bold' }}>
                      Computed Values
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '2px 5px',
                        verticalAlign: 'top',
                      }}
                    >
                      <div style={{ width: '240px' }}>
                        <ValueComponent
                          key={'value-defaultValue'}
                          value={
                            values.find(
                              value => value.key === selectedElement.key,
                            )?.defaultValue ?? ''
                          }
                          type={selectedElement.type}
                          isEditable={true}
                          onValueUpdated={newValue => {
                            updateKeyValue(selectedElement.key, '', newValue);
                          }}
                        />
                      </div>
                    </td>
                    {treeParam.overrideOrder.map((override, index) => {
                      if (
                        !hideParams?.includes(index.toString()) &&
                        atLeastOneKeyExists(override)
                      ) {
                        if (isExpanded[index]) {
                          return (
                            <td
                              key={override.name + index}
                              style={{
                                padding: '2px 5px',
                                verticalAlign: 'top',
                              }}
                            >
                              <table border={1}>
                                <tbody>
                                  {generateCombinations(
                                    treeParam.parameters,
                                    override,
                                  ).map(key => {
                                    if (shouldKeyBeShown(key)) {
                                      return (
                                        <tr key={key}>
                                          <td
                                            style={{
                                              padding: '2px 5px',
                                              verticalAlign: 'top',
                                              width: '0.1%',
                                              whiteSpace: 'nowrap',
                                            }}
                                          >
                                            {key}
                                          </td>
                                          {getOverride(
                                            values,
                                            selectedElement.key,
                                            key,
                                          ) ? (
                                            <td
                                              style={{
                                                padding: '2px 5px',
                                                backgroundColor:
                                                  getOverrideColor(
                                                    treeParam.overrideOrder,
                                                    treeParam.parameters,
                                                    override,
                                                    values,
                                                    selectedElement.key,
                                                    key,
                                                  ),
                                                whiteSpace: 'nowrap',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: '200px',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                }}
                                              >
                                                <ValueComponent
                                                  key={'value-' + key}
                                                  value={getOverride(
                                                    values,
                                                    selectedElement.key,
                                                    key,
                                                  )}
                                                  type={selectedElement.type}
                                                  isEditable={true}
                                                  onValueUpdated={newValue => {
                                                    updateKeyValue(
                                                      selectedElement.key,
                                                      key,
                                                      newValue,
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </td>
                                          ) : (
                                            <td
                                              style={{
                                                textAlign: 'center',
                                              }}
                                            >
                                              <ValueComponent
                                                key={'value-' + key}
                                                value="-"
                                                type={selectedElement.type}
                                                isEditable={true}
                                                onValueUpdated={newValue => {
                                                  updateKeyValue(
                                                    selectedElement.key,
                                                    key,
                                                    newValue,
                                                  );
                                                }}
                                              />
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    }
                                    return '';
                                  })}
                                </tbody>
                              </table>
                            </td>
                          );
                        } else {
                          return (
                            <td style={{ backgroundColor: 'lightgrey' }}></td>
                          );
                        }
                      }
                      return '';
                    })}
                    <td style={{ padding: '2px 5px', verticalAlign: 'top' }}>
                      <table border={1}>
                        <tbody>
                          {generateFinalValues(
                            treeParam.parameters,
                            treeParam.overrideOrder,
                            values.find(
                              value => value.key == selectedElement.key,
                            )!,
                            // @ts-ignore
                          ).map(([key, [value, lastOverride]]) => {
                            if (shouldKeyBeShown(key.split('-').join('_'))) {
                              return (
                                <tr key={'last' + key}>
                                  <td
                                    style={{
                                      padding: '2px 5px',
                                      verticalAlign: 'top',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {lastOverride}
                                  </td>
                                  <td
                                    style={{
                                      padding: '2px 5px',
                                      verticalAlign: 'top',
                                      width: '0.1%',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {key}
                                  </td>
                                  <td
                                    style={{
                                      padding: '2px 5px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '240px',
                                      }}
                                    >
                                      <ValueComponent
                                        key={'value-' + key}
                                        value={value}
                                        type={selectedElement.type}
                                        isEditable={false}
                                        onValueUpdated={_ => {}}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
        </div>
      </div>
    </>
  );
};

type ValueProps = {
  value: string | number | boolean | string[] | null;
  type: JSONType;
  isEditable: boolean;
  onValueUpdated: (newValue: string) => void;
};

// @ts-ignore
export const ValueComponent = ({
  value,
  type,
  isEditable,
  onValueUpdated,
}: ValueProps) => {
  const [currentValue, setCurrentValue] = useState(value?.toString() ?? '-');
  const [inEditState, setEditState] = useState(false);

  const positiveDecimalRegex: RegExp = /^\d+(\.\d+)?$/;
  const booleanRegex: RegExp = /^[01-]$/;
  const listRegex: RegExp = /^(\w+)(\|\s*\w+)*$/;

  function isValidValue(type: JSONType, value: string) {
    if (value === '') return true;
    switch (type) {
      case JSONType.string:
        return true;
      case JSONType.stringArray:
        return listRegex.test(value);
      case JSONType.number:
        return positiveDecimalRegex.test(value);
      case JSONType.boolean:
        return booleanRegex.test(value);
    }
    return false;
  }

  if (inEditState) {
    return (
      <>
        {(type === JSONType.string || type === JSONType.number) && (
          <textarea
            value={currentValue}
            onChange={event => {
              setCurrentValue(event.target.value);
            }}
            style={{
              backgroundColor: isValidValue(type, currentValue)
                ? 'lightgreen'
                : 'pink',
              minWidth: '100%',
              maxWidth: '100%',
            }}
          ></textarea>
        )}
        {type === JSONType.stringArray && Array.isArray(value) && (
          <textarea
            value={currentValue}
            onChange={event => {
              setCurrentValue(event.target.value);
            }}
            style={{
              backgroundColor: isValidValue(type, currentValue)
                ? 'lightgreen'
                : 'pink',
              minWidth: '100%',
              maxWidth: '100%',
            }}
          ></textarea>
        )}
        {type === JSONType.stringArray && !Array.isArray(value) && (
          <textarea
            value={currentValue ?? '-'}
            onChange={event => {
              setCurrentValue(event.target.value);
            }}
            style={{
              backgroundColor: isValidValue(type, currentValue)
                ? 'lightgreen'
                : 'pink',
              minWidth: '100%',
              maxWidth: '100%',
            }}
          ></textarea>
        )}
        {type == JSONType.boolean && (
          <select
            onChange={event => {
              if (event.target.value == 'True') {
                setCurrentValue('1');
                return;
              }
              if (event.target.value == 'False') {
                setCurrentValue('0');
                return;
              }
            }}
          >
            <option>-</option>
            <option>True</option>
            <option>False</option>
          </select>
        )}
        <br />
        <button
          style={{
            cursor: 'pointer',
            width: '100%',
            fontWeight: 'bold',
          }}
          onClick={() => {
            setEditState(!inEditState);
            currentValue !== value &&
              isValidValue(type, currentValue) &&
              onValueUpdated(currentValue);
          }}
        >
          ✅ Validate
        </button>
      </>
    );
  } else {
    switch (type) {
      case JSONType.string:
      case JSONType.number:
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              isEditable && setEditState(!inEditState);
            }}
          >
            {value}
          </span>
        );
      case JSONType.stringArray:
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              isEditable && setEditState(!inEditState);
            }}
          >
            {Array.isArray(value) ? '[ ' + value.join('|') + ' ]' : '-'}
          </span>
        );
      case JSONType.boolean:
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              isEditable && setEditState(!inEditState);
            }}
          >
            {value === '-' && value}
            {value === '1' && 'True'}
            {value === '0' && 'False'}
          </span>
        );
    }
  }
};
