import { useEffect, useMemo, useRef } from "react";

import styles from "./[id].module.css";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { RingBadge } from "../../components/techRadarSpike/Badge/Badge";
import { ItemDetail } from "../../components/techRadarSpike/ItemDetail/ItemDetail";
import { ItemList } from "../../components/techRadarSpike/ItemList/ItemList";
import {
  getLabel,
  getQuadrant,
  sortByFeaturedAndTitle,
} from "../../utils/radar/data";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dhDockStore } from "../../redux/store";
import { ErrorPage } from "../../components/ui/Notifications";
import { Header } from "@backstage/core-components";
import { fetchTechRadarAsync } from "../../redux/slices/techRadarSlice";
import { QuadrantLink } from "../../components/techRadarSpike/QuadrantLink/QuadrantLink";
import { useAppSelector } from "../../hooks/reduxhooks";

const ItemPage = () => {
  const navigate=useNavigate();
  
  const quadrantRef = useRef<HTMLDivElement | null>(null);
  const {quadrant,id}=useParams()
  const quadrantData = getQuadrant(quadrant as string);
  const {techRadarArr,loading} = useAppSelector((state)=>state.techRadar)
  const item = techRadarArr.find(x=>x?.id===id||'');
  const relatedItems = useMemo(() => {
    if(techRadarArr.length>0){
      return techRadarArr
      .filter((i) => i.quadrant === quadrantData?.id && i?.ring === item?.ring)
      .sort(sortByFeaturedAndTitle);
    }
    return []
  }, [techRadarArr, quadrantData?.id, item?.ring]);

  useEffect(()=>{
   dhDockStore.dispatch(fetchTechRadarAsync());
  },[])

  useEffect(()=>{
    if(quadrantRef.current){
      quadrantRef.current.scrollTo({ top: 0, behavior: "smooth" })
    }
  },[item])

  if (!quadrant || !item && !loading) return <><Header title="Tech Radar" /><ErrorPage type="error" message={`Quadrant ${quadrant} and technology ${id} not found `}/></>;

  return (
    <>
    <Header title={quadrantData?.title??''} />
    <div
    ref={quadrantRef}
    style={{
      height: "100vh",    
      marginBottom:'20px',         
      overflowY: "scroll",        
      scrollbarWidth: "none",    
      WebkitOverflowScrolling: "touch",
    }}
  >
      <div className={styles.layout}>
        <section className={styles.content}>
        <ArrowBackIcon style={{fontSize:'40px',cursor:'pointer'}} onClick={()=>navigate("/techRadar")}/>
          <ItemDetail item={item??[]} editLink={item?.body} />
        </section>
        <aside className={styles.sidebar}>
          <h1>{quadrantData?.title??''}</h1>
          <div className={styles.ringAndQuadrant}>
            <RingBadge ring={item?.ring} />
            <QuadrantLink
              quadrant={quadrantData!!}
              label={getLabel("quadrantOverview")}
            />
          </div>

          <ItemList items={relatedItems} activeId={item?.id} />
        </aside>
      </div>
    </div>
    </>
  
  );
};

export default ItemPage;

