import styles from './ItemList.module.css';

import { FlagBadge, RingBadge } from '../Badge/Badge';
import { getQuadrant } from '../../../utils/radar/data';
import { Item } from '../../../utils/radar/types';
import { cn } from '../../../utils';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface ItemListProps {
  items: Item[];
  activeId?: string;
  size?: 'small' | 'default' | 'large';
  hideRing?: boolean;
  className?: string;
}

export function ItemList({
  items,
  activeId,
  size = 'default',
  hideRing = false,
  className,
}: ItemListProps) {
  const navigate = useNavigate();
  const handleOnClick = (data: any) => {
    navigate(`/${data.quadrant}/${data.id}`);
  };
  return (
    <ul
      className={cn(styles.list, className, {
        [styles.isSmall]: size === 'small',
        [styles.isLarge]: size === 'large',
      })}
    >
      {items.map(item => (
        <li className={styles.item} key={item.id}>
          <a
            className={cn(styles.link, {
              [styles.isFadedOut]: !item.featured,
              [styles.isActive]: item.id === activeId,
            })}
            onClick={() => handleOnClick(item)}
            // href={item.body} target="_blank" rel="noopener noreferrer"
          >
            <span className={styles.title}>{item.title}</span>
            <FlagBadge
              className={styles.flag}
              flag={item.flag}
              short={size == 'small'}
            />

            {size === 'large' && (
              <div className={styles.info}>
                <span className={styles.quadrant}>
                  {getQuadrant(item.quadrant)?.title}
                </span>
                {!hideRing && (
                  <RingBadge
                    className={styles.ring}
                    ring={item.ring}
                    size="small"
                  />
                )}
              </div>
            )}
          </a>
        </li>
      ))}
    </ul>
  );
}
