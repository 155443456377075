import { marked } from 'marked';
import React, { useEffect, useState } from 'react';

import { MermaidAddon } from '../../pages/Adr/Mermaid';
import { getBase64ImageByUrl } from '../../services/image.service';

const yaml = require('js-yaml');

const yamlFrontmatterRegex = /^---\s*\r?\n([\s\S]*?)\r?\n---\s*\r?\n/m;
const mermaidRegex = /```[\s]*mermaid([\s\S]*?)```/g;
export const RenderMarkDown = ({ retrievedContents, url }: any) => {
  let htmlText = retrievedContents;

  // Handle links
  htmlText = htmlText?.replace(
    /\[([^\]]+)\]\(([^)]+)\)/g,
    '<a href="$2">$1</a>',
  );

  // Handle bold and italic
  htmlText = htmlText?.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  htmlText = htmlText?.replace(/__(.*?)__/g, '<strong>$1</strong>');
  htmlText = htmlText?.replace(/\*(.*?)\*/g, '<em>$1</em>');
  htmlText = htmlText?.replace(/_(.*?)_/g, '<em>$1</em>');

  // Handle inline code
  htmlText = htmlText?.replace(/`(.*?)`/g, '<code>$1</code>');

  // Handle paragraphs
  htmlText = htmlText?.replace(/^\s*(.*?)\s*$/gm, '<p>$1</p>');

  htmlText = htmlText?.replace(
    /<([^>\s]+)>/g,
    (_: any, url_: string) => `<a href="${url_}">${url_}</a>`,
  );

  return <RenderMdWithInLineImage part={retrievedContents} url={url} />;
};

function renderValue(value: any) {
  if (Array.isArray(value)) {
    return value.join(', ');
  } else if (value instanceof Date) {
    return value.toISOString().split('T')[0];
  }

  return value;
}

function RenderTable({ data }: any) {
  return (
    <table>
      <tbody>
        {Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            <th>{key}</th>
            <td dangerouslySetInnerHTML={{ __html: renderValue(value) }} />
          </tr>
        ))}
      </tbody>
    </table>
  );
}

async function getImage(imgName: string, url: string) {
  url=url.replace('url:','');
  if(url.endsWith('.md')){
    url=url.replace('.md','')
  }
  if(imgName.startsWith('./')){
    // point to the root folder of the adr
    url=url.substring(0,url.lastIndexOf('/')).concat('/');
  }
  const response = await getBase64ImageByUrl(
    url.concat('/').concat(imgName.replace('./', '')).replace('%2F', '/'),
  );
  return response;
}

function RenderMdWithInLineImage({ part, url }: any) {
  const [markdownContent, setMarkdownContent] = useState(part);
  const [yamlData, setYamlData] = useState(null);
  const [diagrams, setDiagrams] = useState<any[]>([]);

  useEffect(() => {
    const fetchImagesAndConvertToBase64 = async () => {
      setYamlData(null);
      const imgTagRegex = /!\[.*?\]\((.*?\.svg|.*?\.png|.*?\.jpg)\)/g;
      let match;
      const imagePromises = [];
      const images = [];

      // eslint-disable-next-line no-cond-assign
      while ((match = imgTagRegex.exec(part)) !== null) {
        const altText = match[1];
        const imageUrl = match[2];
        images.push({ original: match[0], altText, imageUrl });
        imagePromises.push(getImage(altText, url));
      }

      // Resolve all promises and update the markdown content with base64 images
      const base64Images = await Promise.all(imagePromises);
      let updatedContent = part;
      if (base64Images.length > 0) {
        for (let i = 0; i < base64Images.length; i++) {
          const base64Image = base64Images[i];
          if (base64Image) {
            const { original } = images[i];
            let img_;
            try {
              if(atob(base64Image).includes('<svg')){
                img_=`data:image/svg+xml;base64,${base64Image}`
              }
              else{
                img_=`data:image/png;base64,${base64Image}`
              }
            } catch (error) {
              
            }
            finally{
              const imageTag = `![${images[i].altText}](${img_})`;
              updatedContent = updatedContent.replace(original, imageTag);
            }
            
          }
        }
      }

      // Handling YAML front matter
      if (yamlFrontmatterRegex.test(updatedContent)) {
        const splitYamlformatter = updatedContent
          .split(yamlFrontmatterRegex)
          .slice(1);
        const yamlContent = splitYamlformatter[0];

        try {
          const parsedYAML = yaml.load(yamlContent);
          setYamlData(parsedYAML);
        } catch (error) {
          return;
        }

        // Remove YAML front matter from the markdown content
        updatedContent = updatedContent.replace(yamlFrontmatterRegex, '');
      }

      const mermaidMatches = Array.from(
        updatedContent.matchAll(mermaidRegex),
      ) as any;
      const diagramsList = mermaidMatches.map((match_: any) => match_[1].trim());
      setDiagrams(diagramsList);
      // Generate SVGs and replace placeholders
      diagramsList.map((diagram: string, index: number) => {
        updatedContent = updatedContent.replace(
          mermaidMatches[index][0],
          'mer_maid' .concat(diagram).concat('mer_maid'),
        );
      });

      setMarkdownContent(updatedContent);
    };

    fetchImagesAndConvertToBase64();
  }, [part, url]);

  return (
    <>
      {yamlData && <RenderTable data={yamlData} />}
      {markdownContent.split('mer_maid').map((item: string, index: number) => {
        if (diagrams.includes(item)) {
          return (
            <MermaidAddon
              key={index}
              retrievedContent={item}
            />
          );
        }
        return (
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: marked.parse(item).toString() }}
          />
        );
      })}
    </>
  );
  
}
