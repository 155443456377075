import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface ownersProps {
  owners: any[];  loading: boolean;
  error: string | null;
}


const initialState: ownersProps = {
  owners: [],
  loading: false,
  error: null,
};
export const fetchOwnersAsync = createAsyncThunk<string[], void>(
  'owners/fetchOwners',
  async () => {
    const response = await fetchData(urls.getAllProcessedOwners);
    return response; 
  },
);


export const ownerShipSlice = createSlice({
  name: 'owners',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(fetchOwnersAsync.pending, state => {
        state.loading = true;
        state.error = null;
      })

      .addCase(
        fetchOwnersAsync.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.owners = action.payload;
          state.loading = false;
        },
      )

      .addCase(fetchOwnersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch owners';
      });
  },
});


export default ownerShipSlice.reducer;
