import { CSSProperties, useMemo } from "react";

import styles from "./Label.module.css";

import { QuadrantLink } from "../QuadrantLink/QuadrantLink";
import { getLabel } from "../../../utils/radar/data";
import { Quadrant } from "../../../utils/radar/types";
import { cn } from "../../../utils";
import React from "react";
import { techRaderProps } from "packages/app/src/utils/radar/data/types";

interface LabelProps extends techRaderProps {
  quadrant: Quadrant;
}

export function Label({ quadrant,navigateById }: LabelProps) {
  const style = useMemo(
    () => ({ "--quadrant-color": quadrant.color }) as CSSProperties,
    [quadrant.color],
  );

  return (
    <div
      className={cn(styles.label, styles[`position-${quadrant.position}`])}
      style={style}
    >
      <div className={styles.header}>
        <span>
          {getLabel("quadrant")} {quadrant.position}
        </span>
        <QuadrantLink quadrant={quadrant} navigateById={navigateById} />
      </div>
      <h3 className={styles.title}>{quadrant.title}</h3>
      <p className={styles.description}>{quadrant.description}</p>
    </div>
  );
}
