import { createSlice } from '@reduxjs/toolkit';

const initialToken = localStorage.getItem('userToken');

export interface authProps{
  token:null
}
const authSlice = createSlice({
  name: 'auth',
  initialState: { token: initialToken },
  reducers: {
    setUserToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('userToken', action.payload);
    },
    clearUserToken: state => {
      state.token = null;
      localStorage.removeItem('userToken');
    },
  },
});

export const { setUserToken, clearUserToken } = authSlice.actions;
export default authSlice.reducer;
