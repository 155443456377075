/* eslint-disable */

// ESLint will ignore this entire file
import React, { useState } from 'react';
import { JSONType } from '../TreeTypeComponent/TreeTypeComponent';
import { DisplayTypeProps, EditableTypeProps } from './types';

export const EditableTypeComponent = ({
  type,
  disallowedTypes = [],
  onTypeChange,
}: EditableTypeProps) => {
  const [inEditState, setEditState] = useState(false);

  if (inEditState) {
    return (
      <select
        name="type"
        id="type"
        onChange={event => {
          setEditState(!inEditState);
          // @ts-ignore
          onTypeChange(JSONType[event.target.value]);
        }}
      >
        <option style={{ color: getTypeColor(type) }} value={type}>
          <DisplayTypeComponent type={type} />
        </option>

        {Object.keys(JSONType)
          .filter(
            // @ts-ignore
            v => isNaN(Number(v)) && !disallowedTypes.includes(JSONType[v]),
          )
          .map((typeString: any) => {
            // @ts-ignore
            if (JSONType[typeString] != type) {
              return (
                <option
                  // @ts-ignore
                  style={{ color: getTypeColor(JSONType[typeString]) }}
                  value={typeString}
                >
                  {typeString}
                </option>
              );
            }
            return null;
          })}
      </select>
    );
  } else {
    return (
      <>
        <DisplayTypeComponent type={type} />
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => setEditState(!inEditState)}
        >
          <span style={{ fontWeight: 'bold', color: 'grey' }}>{' ⇄'}</span>
        </span>
      </>
    );
  }
};

const getTypeColor = (type?: JSONType) => {
  switch (type) {
    case JSONType.string:
      return 'green';
    case JSONType.number:
      return '#FF517D';
    case JSONType.boolean:
      return 'coral';
    case JSONType.stringArray:
      return 'purple';
    case JSONType.object:
      return 'blue';
    default:
      return 'red';
  }
};

export const DisplayTypeComponent = ({ type }: DisplayTypeProps) => {
  let typeString = type != undefined ? JSONType[type] : 'undefined';
  if (type == JSONType.stringArray) typeString += ' [ ]';
  if (type == JSONType.object) typeString += ' { }';
  return <span style={{ color: getTypeColor(type) }}>{typeString}</span>;
};
