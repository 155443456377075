import { useEntity } from '@backstage/plugin-catalog-react';
import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';
import React, {  useCallback, useEffect, useState } from 'react';
import VulnerabilityChart from './VulnerabilityChart';
import { LinearProgress } from '@material-ui/core';
import { ErrorPage } from '../../components/ui/Notifications';

const CheckMarx = () => {
  const [reportSummary,setReportSummary]=useState();
  const [isLoading,setIsLoading]=useState(true);
  const { entity } = useEntity();
  const getProjectSummary = useCallback(async () => {
    try {
      const summary = await fetchData(
        `${urls.checkmarxScanResultByProject}${entity.metadata.annotations!!['checkmarx.com/project']}`,
      );
      setReportSummary(summary)
      setIsLoading(e=>!e)

    } catch (error) {
      setIsLoading(e=>!e)
    }
    
  }, []);

  useEffect(() => {
    getProjectSummary();
  }, [getProjectSummary]);

  if(isLoading){
    return <LinearProgress/>
  }
  if(!isLoading && !reportSummary){
    return <ErrorPage message={`No data found for ${entity.metadata.annotations!!['checkmarx.com/project']}`} type='error' />
  }
  
  return reportSummary && <div> <VulnerabilityChart data={reportSummary}/></div>;
};

export default CheckMarx;
