
import {  createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData, urls } from '@internal/backstage-plugin-dh-dock-shared-common';

export interface techRadarArrProps {
  techRadarArr: any[]; 
  loading: boolean;
  error: string | null;
}
const initialState: techRadarArrProps = {
  techRadarArr: [],
  loading: false,
  error: null,
};
const getJsonContentByLocation = async () => {
  const list = [] as any;
  const urlLocation = (await fetchData(
    urls.getConfigByName.concat('newtechRadar.location'),
  )) as string;
      if (location) {
        const getAllFilesInLocation = await fetchData(
          urls.getAdrList.concat(urlLocation ?? ''),
        );
        if (getAllFilesInLocation) {

          for (const data of getAllFilesInLocation.data) {
            const url = urlLocation.concat('/').concat(data?.path);
            const location = await fetchData(
              urls.getAdrContentByName.concat(url),
            );
            list.push(JSON.parse(location?.data));
          }
          const mergedArray = list.flatMap((arr: any) => arr);
          const distinctList = mergedArray.filter((item: any, index: any, self: any[]) => {
            return self.findIndex((el: any) => el?.title?.toLowerCase() === item?.title?.toLowerCase()) === index;
        });

          return distinctList
        
        }
      }
    };
export const fetchTechRadarAsync = createAsyncThunk<[], void>(
  'techRadar/fetchData',
  async () => {
     return await getJsonContentByLocation();
  },
);


const techRadarSlice = createSlice({
  name: 'techRadar',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder

      .addCase(fetchTechRadarAsync.pending, state => {
        state.loading = true;
        state.error = null;
      })

      .addCase(
        fetchTechRadarAsync.fulfilled,
        (state, action) => {
          state.techRadarArr = action.payload;
          state.loading = false;
        },
      )

      .addCase(fetchTechRadarAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch tech Radar Data';
      });
  },
 
});


export default techRadarSlice.reducer;
