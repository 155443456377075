

import React from 'react';
import { HomePageStarredEntities } from "@backstage/plugin-home";
import { Content, DocsIcon, Header, LinkButton, Page } from "@backstage/core-components";
import { HomePageSearchBar } from "@backstage/plugin-search";
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import CategoryIcon from "@material-ui/icons/Category";
import { WelcomeTitle } from "./WelcomeTitle";

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: 'none'
    },
    floatingButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#05164d',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        fontSize: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      },
      hover: {
        backgroundColor: '#05164d',
      },
}));

export const HomePage = () => {
    const classes = useStyles();

    const navigateToBoard=()=>{
        return window.open("https://dev.azure.com/lhg-ddl/CoP-DevSecOps-Engineering/_workitems/create/Product%20Backlog%20Item?templateId=3460b89b-06c3-4e60-bf8c-8adbb26c8b1c&ownerId=61ea317b-b78a-409d-bd50-d01d422c2d30","__blank")
    }
  
    const FloatingButton = () => {
        
        return (
            <Tooltip title="Submit a Feature Request" arrow>
            
            <button className={`${classes.floatingButton} ${classes.hover}`} onClick={navigateToBoard}>
            +
          </button>
          </Tooltip>
        );
      };
      
    
    return (<Page themeId="home">
        <Header title={<WelcomeTitle />} pageTitleOverride="Home" />
        <Content>
            <Grid container justifyContent="center" spacing={3} style={{ minHeight: '100vh' }}>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                >
                    <LinkButton
                        startIcon={<DocsIcon />}
                        size="large"
                        variant="contained"
                        color="primary"
                        style={{ margin: '1rem' }}
                        to="/docs/default/System/DH-Dock/tutorials/onboarding/"
                    >
                        Get Onboarded to DH-Dock
                    </LinkButton>
                    <LinkButton
                        startIcon={<CategoryIcon />}
                        size="large"
                        variant="outlined"
                        style={{ margin: '1rem' }}
                        to="/docs/default/System/DH-Dock"
                    >
                        Read the DH-Dock Docs
                    </LinkButton>
                </Grid>
                <Grid item xs={12}>
                    <HomePageSearchBar
                        InputProps={{
                            classes: {
                                root: classes.searchBarInput,
                                notchedOutline: classes.searchBarOutline
                            }
                        }}
                        placeholder="Search"
                    />
                </Grid>
                <Grid item md={6}>
                    <HomePageStarredEntities />
                </Grid>
            </Grid>
            <FloatingButton/>
        </Content>
    </Page>
    );
};