import styles from "./RingList.module.css";

import { RingBadge } from "../Badge/Badge";
import { ItemList, ItemListProps } from "../ItemList/ItemList";
import { groupItemsByRing } from "../../../utils/radar/data";
import { Item } from "../../../utils/radar/types";
import { cn } from "../../../utils/";
import React from "react";

interface RingListProps {
  items: Item[];
  size?: ItemListProps["size"];
}
export function RingList({ items, size }: RingListProps) {
  const rings = groupItemsByRing(items);
  return (
    <ul className={cn(styles.rings, { [styles.isSmall]: size == "small" })}>
      {Object.entries(rings).map(([ring, items]) => {
        return (
          <li key={ring} className={styles.ring}>
            <RingBadge className={styles.badge} ring={ring} />
            <ItemList className={styles.list} items={items} size={size} />
          </li>
        );
      })}
    </ul>
  );
}
