/* eslint-disable */

// ESLint will ignore this entire file
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Header, Page, Content, HeaderLabel } from '@backstage/core-components';
import { TreeTypeComponent } from '../TreeTypeComponent';
import { TreeParamComponent } from '../TreeParamComponent';
import {
  JSONType,
  NodeData,
  Value,
} from '../TreeTypeComponent/TreeTypeComponent';
import {
  Param,
  Override,
  TreeParam,
} from '../TreeParamComponent/TreeParamComponent';
import {
  fetchData as fetchCustomData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';
import LinearProgress from '@mui/material/LinearProgress';

export type Team = {
  name: string;
  groups: string[];
};

export const FeatureFlagsComponent = () => {
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const team = await fetchCustomData(urls.getTeams);
      setTeams(team);
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      }
    } finally {
      setLoading(false);
      if (teamNameParameter != '') {
        setSelectedTeam(teamNameParameter);
        fetchData(teamNameParameter);
      }
    }
  };

  
  const fetchData = async (team: string) => {
    try {
      const response = await fetchCustomData(`${urls.getTeamConfig}${team}`);
      setNodeDatas([...response.nodeDatas]);
      setTreeParam({ ...response.treeParam });
      setValues([...response.values]);
    } catch (err) {
      if (err instanceof Error) console.log(err.message);
    }
  };

  const setData = async () => {
    const response = await fetchCustomData(`${urls.setConfig}${selectedTeam}`, {
      nodeDatas,
      treeParam,
      values,
    });
    try {
      setNodeDatas([...response.nodeDatas]);
      setTreeParam({ ...response.treeParam });
      setValues([...response.values]);
      setNeedsSaving(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [needsSaving, setNeedsSaving] = useState(false);

  const [teams, setTeams] = useState([] as Team[]);
  const [selectedTeam, setSelectedTeam] = useState('');

  const [nodeDatas, setNodeDatas] = useState([] as NodeData[]);
  const queryParameters = new URLSearchParams(window.location.search);
  const isEditModeEnabled = queryParameters.get('edit') != null;
  const keySelected = queryParameters.get('keySelected');
  const isDebug = queryParameters.get('debug') != null;
  const teamNameParameter =
    window?.location?.pathname?.split('/dap-feature-flags/')[1] ?? '';

  function createEmptyValueOfType(key: string, type: JSONType): Value {
    let defaultValue;
    switch (type) {
      case JSONType.string:
        defaultValue = 'Default';
        break;
      case JSONType.stringArray:
        defaultValue = ['Default'];
        break;
      case JSONType.boolean:
        defaultValue = '0';
        break;
      case JSONType.number:
        defaultValue = 0;
        break;
    }

    let data: Value = {
      key: key,
      type: type,
      defaultValue: defaultValue ?? '',
      overrides: {},
    };
    return data;
  }

  function findValueKeyFromNodeDataId(
    nodeDatas: NodeData[],
    nodeDataId: string,
    previousKey: string = '',
  ): string {
    const idSplitted = nodeDataId.split('.');
    const currentId: number = parseInt(idSplitted[0]);
    // console.log('currentId: ' + currentId);
    const currentNode = nodeDatas[currentId];
    if (currentNode == undefined) return previousKey;
    previousKey =
      previousKey != ''
        ? previousKey + '.' + currentNode.name
        : currentNode.name;
    if (idSplitted.length > 1) {
      return findValueKeyFromNodeDataId(
        currentNode.children!,
        idSplitted.splice(1).join('.'),
        previousKey,
      );
    } else return previousKey;
  }

  const addNodeToTreeData = (newNodeData: NodeData): void => {
    let splitedId = newNodeData.id.split('.');
    let updatedTree = [...nodeDatas];
    findAndAdd(updatedTree, splitedId);
    setNodeDatas(updatedTree);
    const parentKey = findValueKeyFromNodeDataId(
      [...nodeDatas],
      newNodeData.id,
    );
    const valueKey = parentKey == '' ? newNodeData.name : parentKey;
    if (newNodeData.type != JSONType.object)
      setValues([
        ...values,
        createEmptyValueOfType(valueKey, newNodeData.type),
      ]);
    setNeedsSaving(true);

    function findAndAdd(
      tree: NodeData[],
      array: string[],
      prefix: string = '',
    ) {
      if (array.length > 1) {
        prefix = prefix == '' ? array[0] : prefix + '.' + array[0];
        const nextTree = tree.find(element => element.id == prefix);
        findAndAdd(nextTree?.children!, array.slice(1), prefix);
      } else if (array.length == 1) {
        tree.push(newNodeData);
      }
    }
  };

  const updateTreeData = (idToUpdate: string, newNodeData: NodeData): void => {
    const internalUpdateTreeData = (
      data: NodeData[],
      idToUpdate: string,
      newNodeData: NodeData,
    ): NodeData[] => {
      return data.map(item => {
        if (item.id === idToUpdate) {
          return { ...newNodeData };
        } else if (item.children) {
          return {
            ...item,
            children: internalUpdateTreeData(
              item.children,
              idToUpdate,
              newNodeData,
            ),
          };
        }
        return item;
      });
    };
    setNodeDatas(internalUpdateTreeData(nodeDatas!, idToUpdate, newNodeData));

    const fromKey = findValueKeyFromNodeDataId([...nodeDatas], idToUpdate);
    let fromKeyArray = fromKey.split('.');
    fromKeyArray.pop();
    fromKeyArray.push(newNodeData.name);
    const toKey = fromKeyArray.join('.');

    // Object updated was previously of type object
    if (values.find(value => value.key == fromKey)?.key == undefined) {
      // console.log('Updating object ' + fromKey + ' of type object');
      if (fromKey == toKey) {
        if (newNodeData.type != JSONType.object) {
          // console.log('type updated to: ' + JSONType[newNodeData.type]);
          const newValues = values.filter(value => {
            if (!value.key.startsWith(fromKey)) return value;
            return value;
          });
          setValues([
            ...newValues,
            createEmptyValueOfType(fromKey, newNodeData.type),
          ]);
        }
      } else {
        // console.log('renamed to: ' + toKey);
        const newValues = values.map(value => {
          return { ...value, key: value.key.replace(fromKey, toKey) };
        });
        setValues(newValues);
      }
    } else {
      // console.log(
      //   'Updating object ' +
      //     fromKey +
      //     ' of type ' +
      //     JSONType[values.find(value => value.key == fromKey)!.type],
      // );
      if (fromKey == toKey) {
        // console.log('type updated to: ' + JSONType[newNodeData.type]);
        if (newNodeData.type == JSONType.object) {
          const newValues = values.filter(value => {
            if (value.key == fromKey) return;
            else return value;
          });
          setValues(newValues);
        } else {
          const newValues = values.map(value => {
            if (value.key == fromKey)
              return createEmptyValueOfType(fromKey, newNodeData.type);
            else return value;
          });
          setValues(newValues);
        }
      } else {
        // console.log('renamed to: ' + toKey);
        const newValues = values.map(value => {
          if (value.key == fromKey) return { ...value, key: toKey };
          else return value;
        });
        setValues(newValues);
        setSelectedElement({ key: toKey, type: newNodeData.type });
      }
    }
    setNeedsSaving(true);
  };

  const deleteNodeToTreeData = (idToDelete: string): void => {
    let nameOfDeletedNode = '';
    setNodeDatas(updateAllIds(findAndRemove(nodeDatas)));

    if (nameOfDeletedNode != '') {
      setValues(
        values.filter(value => !value.key.startsWith(nameOfDeletedNode)),
      );
    }

    function findAndRemove(tree: NodeData[]): NodeData[] {
      return tree.filter(item => {
        if (item.id === idToDelete) {
          nameOfDeletedNode = item.name;
          return false;
        } else if (item.children) {
          item.children = findAndRemove(item.children);
          return true;
        }
        return true;
      });
    }
    function updateAllIds(data: NodeData[], parentId: string = ''): NodeData[] {
      let idCounter = 0;
      return data.map(item => {
        const newId =
          parentId !== '' ? parentId + '.' + idCounter++ : String(idCounter++);
        let children: NodeData[] | undefined;
        if (item.children) {
          children = updateAllIds(item.children, newId);
        }
        return { ...item, id: newId, children };
      });
    }
    setNeedsSaving(true);
  };

  const [treeParam, setTreeParam] = useState({
    parameters: [] as Param[],
    overrideOrder: [] as Override[],
  } as TreeParam);
  const AddParam = (newParam: Param) => {
    setTreeParam({
      ...treeParam,
      parameters: [...treeParam.parameters, newParam],
    });
    setNeedsSaving(true);
  };

  const UpdateParam = (oldParamName: string, newParam: Param) => {
    const oldValues =
      treeParam.parameters.find(param => param.name == oldParamName)?.values ??
      ([] as string[]);
    if (oldValues.length >= newParam.values.length) {
      // A value was removed from the parameter or a parameter renaming happened
      setValues(
        values.map(value => {
          value.overrides = {};
          return value;
        }),
      );
    }
    setTreeParam({
      ...treeParam,
      parameters: treeParam.parameters.map(parameter => {
        if (parameter.name == oldParamName) return { ...newParam };
        else return { ...parameter };
      }),
      overrideOrder: treeParam.overrideOrder.map(override => {
        return renameOverride(override);
      }),
    });
    function renameOverride(override: Override): Override {
      return {
        name: override.name == oldParamName ? newParam.name : override.name,
        child: override.child && renameOverride(override.child),
      };
    }
    setNeedsSaving(true);
  };

  const DeleteParam = (oldParamName: string) => {
    setTreeParam({
      ...treeParam,
      parameters: treeParam.parameters.filter(element => {
        if (element.name != oldParamName) return element;
        return;
      }),
      overrideOrder: [],
    });
    setValues(
      values.map(value => {
        value.overrides = {};
        return value;
      }),
    );
    setNeedsSaving(true);
  };

  const OverrideUpdate = (overrides: Override[]) => {
    setTreeParam({
      ...treeParam,
      overrideOrder: overrides,
    });
    setValues(
      values.map(value => {
        value.overrides = {};
        return value;
      }),
    );
    setNeedsSaving(true);
  };

  const [values, setValues] = useState([] as Value[]);

  function removeOverride(values: Value[], keyToRemove: string): Value[] {
    setNeedsSaving(true);
    return values.map(value => {
      if (value.overrides && keyToRemove in value.overrides) {
        delete value.overrides[keyToRemove];
      }
      return value;
    });
  }

  const updateKeyValueToTreeData = (
    field: string,
    key: string,
    value: string,
  ): void => {
    // If default is modified, key is ''
    if (key == '' && value != '' && value != '-') {
      setValues(
        values.map(entry => {
          if (entry.key != field) return entry;
          else {
            if (
              entry.type == JSONType.stringArray &&
              value.split('|').length > 0
            )
              return { ...entry, defaultValue: value.split('|') };
            else if (entry.type == JSONType.number)
              return { ...entry, defaultValue: Number(value) };
            else return { ...entry, defaultValue: value };
          }
        }),
      );
      setNeedsSaving(true);
      return;
    }
    if (value == null || value == '' || value == '-') {
      setValues(removeOverride(values, key));
    } else
      setValues(
        values.map(entry => {
          if (entry.key != field) return entry;
          else {
            let newOverrides: {
              [key: string]: string | number | boolean | string[];
            } = entry.overrides ?? {};

            if (
              entry.type == JSONType.stringArray &&
              value.split('|').length > 0
            ) {
              newOverrides[key] = value.split('|');
            } else if (entry.type == JSONType.number) {
              newOverrides[key] = Number(value);
            } else {
              newOverrides[key] = value;
            }
            return { ...entry, overrides: { ...newOverrides } };
          }
        }),
      );
    setNeedsSaving(true);
  };

  function findNodeInfoById(
    nodeDatas: NodeData[],
    id: string,
    prefix: string = '',
  ): { key: string; type: JSONType } | null {
    for (const node of nodeDatas) {
      if (node.id === id) {
        return {
          key: prefix != '' ? prefix + '.' + node.name : node.name,
          type: node.type,
        };
      } else if (node.children) {
        const foundInChildren = findNodeInfoById(
          node.children,
          id,
          prefix != '' ? prefix + '.' + node.name : node.name,
        );
        if (foundInChildren) return foundInChildren;
      }
    }
    return null;
  }

  const [selectedElement, setSelectedElement] = useState<{
    key: string;
    type: JSONType;
  } | null>(null);

  useEffect(() => {
    if (keySelected) {
      const newSelectedElement = findNodeInfoById(nodeDatas, keySelected);
      setSelectedElement(newSelectedElement);
    }
  }, [keySelected, nodeDatas]);

  if (loading) {
    return <LinearProgress />; // Render a loading indicator
  }

  return (
    <Page themeId="tool">
      <Header title="Feature flags">
        <HeaderLabel label="Owner" value="DAP" />
      </Header>
      <Content>
        <Grid container spacing={3} direction="column">
          {teamNameParameter == '' && (
            <Grid>
              <div>
                <h1>Teams</h1>
                {teams?.map(team => {
                  return (
                    <div style={{ padding: '20px' }}>
                      <div>
                        <div key={team.name}>
                          <h2>{team.name}</h2>
                          <button
                            onClick={() => {
                              window.location.href =
                                '/dap-feature-flags/' +
                                team.name +
                                window.location.search;
                            }}
                          >
                            Go to {team.name}
                          </button>
                          <h4>Groups with Access:</h4>
                          <ul>
                            {team.groups.map((group, index) => (
                              <li key={index}>{group}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Grid>
          )}

          {selectedTeam && (
            <>
              <Grid>
                <button
                  disabled={!needsSaving}
                  style={{
                    width: '100%',
                    padding: '10px',
                    margin: '10px',
                    backgroundColor: needsSaving ? 'lightpink' : 'lightgreen',
                    cursor: needsSaving ? 'pointer' : 'default',
                  }}
                  onClick={async () => {
                    console.log('Try to save changes in config!');
                    setData();
                  }}
                >
                  Save changes
                </button>
              </Grid>
              {isDebug && (
                <Grid>
                  <div
                    style={{
                      margin: '10px',
                      border: '2px solid red',
                      padding: '8px',
                    }}
                  >
                    <h3>Debug</h3>
                    <div>
                      <button
                        onClick={async () => {
                          console.log('Try to get config!');
                          fetchData(selectedTeam);
                        }}
                      >
                        Reload Config
                      </button>
                    </div>
                  </div>
                </Grid>
              )}
              {(treeParam.parameters.length == 0 ||
                treeParam.overrideOrder.length == 0) && (
                <Grid>
                  <div
                    style={{
                      margin: '10px',
                      border: '2px solid red',
                      padding: '8px',
                    }}
                  >
                    <h3>Configuration is missing some things</h3>
                    {treeParam.parameters.length == 0 && (
                      <div>
                        - Create at least one{' '}
                        <span style={{ fontWeight: 'bold' }}>Parameter</span>
                      </div>
                    )}
                    {treeParam.overrideOrder.length == 0 && (
                      <div>
                        - Add at least one new{' '}
                        <span style={{ fontWeight: 'bold' }}>Override</span>
                      </div>
                    )}
                  </div>
                </Grid>
              )}
              <Grid item>
                {treeParam.parameters.length > 0 &&
                  treeParam.overrideOrder.length > 0 && (
                    <TreeTypeComponent
                      nodeDatas={nodeDatas}
                      treeParam={treeParam}
                      values={values}
                      isEditMode={isEditModeEnabled}
                      selectedElement={selectedElement}
                      addNewNodeData={addNodeToTreeData}
                      updateNodeData={updateTreeData}
                      deleteNodeData={deleteNodeToTreeData}
                      updateKeyValue={updateKeyValueToTreeData}
                      updateSelectedElement={setSelectedElement}
                    />
                  )}
                {isEditModeEnabled && (
                  <TreeParamComponent
                    treeParam={treeParam}
                    onParamAdd={AddParam}
                    onParamUpdate={UpdateParam}
                    onParamDelete={DeleteParam}
                    onOverrideUpdate={OverrideUpdate}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Content>
    </Page>
  );
};
