import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';

import authReducer from './slices/authSlice';
import groupInfoReducer from './slices/customGroupInfoSlice';
import techRadarReducer from './slices/techRadarSlice';
import ownershipReducer from './slices/ownerShipSlice';
import { ownersProps } from './slices/ownerShipSlice';
import {authProps  } from './slices/authSlice';
import {groupInfoArrProps  } from './slices/customGroupInfoSlice';
import {techRadarArrProps  } from './slices/techRadarSlice';

// Define the store state type
export type RootState = {
  ownersProps: ownersProps; // Ensure this matches the state structure
  dhDockComponentStatus: boolean;
  auth:authProps,
  customGroupInfoList:groupInfoArrProps,
  techRadar:techRadarArrProps
};

// Configure the store
export const dhDockStore = configureStore({
  reducer: {
    ownersProps: ownershipReducer,
    auth: authReducer,
    groupInfo:groupInfoReducer,
    techRadar:techRadarReducer
  },
  middleware: getDefaultMiddleware => {
    if (process.env.NODE_ENV !== 'production') {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});

export type AppDispatch = typeof dhDockStore.dispatch;
(window as any).dhDockStore = dhDockStore;
