// @ts-nocheck 

import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, EntityRefLink, useEntity } from '@backstage/plugin-catalog-react';
import { fetchData, hostUrl, urls } from '@internal/backstage-plugin-dh-dock-shared-common';
import { Avatar, Badge, Box, Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import useBackStageApi from '../../../hooks/useBackstageApi';
import { dhDockStore } from '../../../redux/store';
import Security from '@material-ui/icons/Security';

type MemberType = 'contributor' | 'core';
type ChampionType = 'DevOps Champion' | 'Security Champion' | 'DevOps & Security Champion' | 'Operations Champion' | 'none' | undefined;
 
interface MemberListProps {
  targetRef: string;
  type: string;
  role?: string;
}
 
interface UserRoleProps {
  link: string;
  role: string;
}
 
type Team = {
  link?: string;
  role?: string|ChampionType;
  targetRef: string;
  champion?:ChampionType;
  type:"core"|"contributor"
 
};
 
interface ChampsProps {
  targetRef: string;
}
 
const useStyles = makeStyles({
  card: {
    display: 'flex',
    minHeight: '50px',
    height: 'auto',
  },
  emptyCard: {
    marginLeft: 15,
  },
  cardHeader: {
    display: 'flex',
    justifyContent:'space-between',
    '& button': {
      height: '34px',
      lineHeight: '10px',
      marginTop: '16px',
      cursor: 'pointer',
    },
  },
 
  cardData: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    minHeight: '120px',
    paddingTop: '10px',
    marginTop: '40px',
    maxWidth: '200px',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  cardContent: {
    position: 'relative',
    display: 'flex',
    gap: '18px',
    flexWrap: 'wrap',
  },
  name: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '1.6',
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '10px',
  },
  role: {
    color: '#05164d',
    position: 'relative',
    marginTop:'7px',
    bottom: 0,
  },
  avatar: {
    color: 'transparent',
    textAlign: 'center',
    position: 'absolute',
    height: '3em',
    width: '4rem',
    marginTop: '-40px',
  },
  container: {
    display: "flex",
    justifyContent: "end", 
    top: "20px",
    float:'right',
    textAlign:'right',
    marginRight: '30px', 
  },
  legendContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    padding: "10px",
    marginTop:'5px',
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
  },
  legendItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  icon: {
    fontSize: "24px",
  },
  text: {
    fontSize: "14px",
    color: "#333",
  },
});


const Legend = () => {
  const classes = useStyles();
  const icons = [
    { Icon: VerifiedUser, label: "Entra-ID " },
    { Icon: Security, label: "PeopleApp" },
  ];
  

  return (
    <div className={classes.container}>
    <div className={classes.legendContainer}>
      {icons.map(({ Icon, label }, index) => (
        <div key={index} className={classes.legendItem}>
          <Icon className={classes.icon} />
          <span className={classes.text}>{label}</span>
        </div>
      ))}
    </div>
  </div>

  );
};
 
function DisplayCard({ targetRef, role, champion, type }: Team) {
  
  const classes = useStyles();
  const { value } = useBackStageApi(targetRef);

  if (!value || !value.spec || !value.spec.profile) {
    console.error('Invalid entity data:', value);
    return <Card className={classes.cardData}><p>Error loading entity</p></Card>;
  }

  const {
    spec: { profile: { displayName = 'Unknown', picture = '' } = {} },
  } = value as any;

  return (
    <Box>
      <Card variant="elevation" className={classes.cardData}>
        <div style={{ display: 'flex', gap: '60px', marginTop: '40px' }}>
          {role && type === 'core' && (
            <Badge badgeContent={<Security fontSize="medium" />} />
          )}
          {champion !== 'none' && type === 'core' && (
            <Badge
              overlap="circular"
              badgeContent={<VerifiedUser fontSize="small" />}
            />
          )}
          {champion === 'none' && !role && type && (
            <Badge
              overlap="circular"
              badgeContent={<VerifiedUser fontSize="small" />}
            />
          )}
        </div>
        <Avatar alt={displayName} src={picture} className={classes.avatar} />
        
        {value.metadata ? (
        <EntityRefLink entityRef={value} className={classes.name} title={displayName.replace(',', '')} />
      ) : (
        <p>Invalid EntityRef</p>
      )}

        <label className={classes.role}>{role}</label>
        {champion !== 'none' && <label className={classes.role}>{champion}</label>}
      </Card>
    </Box>
  );
}

 
const MembersList: React.FC<{
  members: MemberListProps[];
  name: string;
  groupInfoEntity?: Entity;
  memberType: MemberType;
}> = ({ members, name, groupInfoEntity, memberType }) => {
  document.title = name;
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [groupContributorList, setGroupContributorList] = useState<
    MemberListProps[]
  >([]);
  const { entity } = useEntity();
  const data = members.filter(x => x.type === 'hasMember');
  const nestedMembers = members.filter(x => x.type === 'parentOf');
  const [roleList, setRoleList] = useState<UserRoleProps[]>([]);
  const [nestedMembersCount,setNestedMembersCount]=useState(0)
  const [teamName, setTeamName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [formatedRoles, setFormatedRoles] = useState<MemberListProps[]>([]);
  const store= dhDockStore.getState();
  const groupInfoArr = store.groupInfo.groupInfoArr;

  const [champs, setChamps] = useState({
    securityChamps: [] as ChampsProps[],
    devopsChamps: [] as ChampsProps[],
    operationsManager:[] as ChampsProps[]
  });
 
  const fetchRoles = useCallback(() => {
    if(groupInfoArr && groupInfoArr.length>0){
      const {spec}=entity as any;
      const data=groupInfoArr.find(x=>JSON.parse(x?.unprocessed_entity)?.metadata.name.toLowerCase()===spec?.profile?.displayName.toLowerCase() as any)
      if(data){
        const processData=JSON.parse(data.unprocessed_entity);
        const userRoles = processData?.spec?.userRoles as UserRoleProps[];
        if (userRoles) {
          setRoleList(userRoles);
        }
      }
      else {
        if (groupInfoEntity) {
          const { spec } = groupInfoEntity as any;
          const userRoles = spec?.userRoles as UserRoleProps[];
          if (userRoles) {
            setRoleList(userRoles);
          }
        } 
      }
    }
    
  }, [groupInfoEntity,groupInfoArr]);
 
  const getTeamNameByTeamId = useMemo(async () => {
    const groupItem = {
      kind: 'group',
      name: name ?? 'dh_team_dso',
      namespace: 'default',
    };
    const value = (await catalogApi.getEntityByRef(groupItem)) as any;
    if (!value || !value.spec || !value.spec.profile) {
      console.error('Invalid entity data:', value);
      return null;
    }
     
     return value?.spec.profile?.displayName;

  }, [name, catalogApi]);
 
  useEffect(() => {
    if (data.length > 0) {
      fetchRoles();
    }
  }, [data, fetchRoles]);
 
  useEffect(() => {
    getTeamNameByTeamId.then(setTeamName);
  }, [name, data, getTeamNameByTeamId]);
 
  useEffect(() => {
    HandleSort();
  }, [roleList]);
 
  useEffect(() => {
    const getContributorMembers = async () => {
      try {
        const name = entity.metadata.name.concat('_contributors');
        const response = await fetchData(
          `${urls.getContributorsByTeam}${name}`,
        );
        const data = response.relations?.filter(
          (x: MemberListProps) => x.type === 'hasMember',
        );
        setIsLoading(false);
        setGroupContributorList(data);
      } catch (exception) {
        if (exception instanceof Error) {
          setIsLoading(false);
        }
      }
    };
    if(memberType==='contributor'){
      getContributorMembers();
    }
  }, [memberType]);
 
  const getAllChampionsList = useCallback(async () => {
    try {
      // Fetch all champion lists in parallel
      const [securityChampionsList, devopsChampionsList, operationsManagerList] = await Promise.all([
        fetchData(`api/catalog/entities/by-name/group/default/dh_champ_security`),
        fetchData(`api/catalog/entities/by-name/group/default/dh_champ_devops`),
        fetchData(`api/catalog/entities/by-name/group/default/dh_champ_ops`)
      ]);
  
      // Extract champions from each list
      const devopsChamps = devopsChampionsList?.relations?.filter((x: { type: string; }) => x.type === 'hasMember') || [];
      const securityChampions = securityChampionsList?.relations?.filter((x: { type: string; }) => x.type === 'hasMember') || [];
      const operationChampsList = operationsManagerList?.relations?.filter((x: { type: string; }) => x.type === 'parentOf') || [];
  
      // Fetch operation champions in parallel
      const operationChamps = await Promise.all(
        operationChampsList.map((group: { targetRef: string; }) =>
          fetchData(`api/catalog/entities/by-name/group/default/${group.targetRef.split('/')[1]}`)
            .then(response => response?.relations?.filter((x: { type: string; }) => x.type === 'hasMember') || [])
        )
      );
  
      // Flatten the results
      const operationsManager = operationChamps.flat();
  
      // Update state
      setChamps(prev => ({
        ...prev,
        devopsChamps,
        securityChamps:securityChampions,
        operationsManager
      }));
    } catch (error) {
      console.error("Error fetching champions:", error);
      setChamps(prev => ({
        ...prev,
        devopsChamps: [],
        securityChampions: [],
        operationsManager: [],
      }));
    }
  }, [hostUrl]);
 
  const getNestedMembers=useCallback(async()=>{
    const operationChamps = await Promise.all(
      nestedMembers.map((group: { targetRef: string; }) =>
        fetchData(`api/catalog/entities/by-name/group/default/${group.targetRef.split('/')[1]}`)
          .then(response => response?.relations?.filter((x: { type: string; }) => x.type === 'hasMember') || [])
      )
    );
    const operationChamps_List=operationChamps.flat();
    setNestedMembersCount(operationChamps_List.length)
    return operationChamps_List
  },[])
 
  useEffect(() => {
    if(nestedMembers.length>0){
      getNestedMembers()
    }else
    getAllChampionsList();
  }, [getAllChampionsList,getNestedMembers]);
 
  const HandleSort = async() => {
    setIsLoading(false);
    let formatedData:any[]=[] as any
    if(data.length>0){
      formatedData = data.map(x => {
        x.role = roleList?.find(z => z.link.toLowerCase() === x.targetRef.toLowerCase())?.role;
        return x;
      });
    }else if(nestedMembers.length>0){
      const nestedMembersList=await getNestedMembers();
      formatedData = nestedMembersList.map(x => {
        if(x?.role){
          x.role = roleList?.find(z => z.link.toLowerCase() === x.targetRef.toLowerCase())?.role;
        }
        else{
          x.role="Operations Champion"
        }
        return x;
      });
    }
    setFormatedRoles(formatedData);
  };
  
  
  return (
    <Card className={memberType === 'core' ? classes.card : ''}>
      {isLoading && <LinearProgress />}
 
      <div className={classes.cardHeader}>
        <CardHeader 
          title={`${memberType === 'core' ? `Core Members (${data.length || nestedMembersCount})` : `Contributor Members (${groupContributorList?.length ?? 0})`} `}
        />
        {memberType==='core'&&<Legend/>}
      </div>
 
      {data.length > 0 && (
        <label className={classes.emptyCard}> of {teamName ?? name}</label>
      )}
      <label className={classes.emptyCard}>
        {data.length < 1 && nestedMembersCount<1 && 'No members found'}
      </label>
 
      <CardContent className={classes.cardContent}>
        {memberType === 'core' &&
          formatedRoles?.length > 0 &&
          formatedRoles
            .sort((a: any, b: any) => {
              return a.role?.localeCompare(b.role);
            })
            .map(x => {
              return (
                <React.Fragment key={x.targetRef}>
                  <DisplayCard
                    key={x.targetRef}
                    targetRef={x.targetRef.replaceAll('@','_')}
                    role={x
                      .role!!?.replace('/ DevOps Champion', '')
                      .replace('/ Security Champion', '')
                      .replace('DevOps Champion', '')
                      .replace('Security Champion', '')}
                    champion={
                      champs.devopsChamps.concat(champs.securityChamps).filter(a=>a.targetRef===x.targetRef).length>1?
                      'DevOps & Security Champion':
                      champs.devopsChamps.find(y => y.targetRef === x.targetRef)
                        ? 'DevOps Champion'
                        : champs.securityChamps.find(
                              z => z.targetRef === x.targetRef,
                            )
                          ? 'Security Champion'
                          : champs.operationsManager.find( z => z.targetRef === x.targetRef)? 'Operations Champion'
                          : 'none'
                    
                    }
                    type='core'
                  />
                </React.Fragment>
              );
            })}
        
        {memberType === 'contributor' &&
          groupContributorList?.length > 0 &&
          groupContributorList.map(x => {
            return (
              <React.Fragment key={x.targetRef}>
                <DisplayCard
                  key={x.targetRef}
                  targetRef={x.targetRef}
                  role={x.role!!}
                  type='contributor'
                />
              </React.Fragment>
            );
          })}
      </CardContent>
    </Card>
  );
};
 
export default MembersList;
 