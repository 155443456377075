import React, { useEffect, useMemo, useState } from 'react';
import { QuadrantList } from '../../components/techRadarSpike/QuadrantList/QuadrantList';
import { Radar } from '../../components/techRadarSpike/Radar/Radar';
import { Tags } from '../../components/techRadarSpike/Tags/Tags';
import {
  getChartConfig,
  getQuadrants,
  getRings,
  getSections,
  getTags,
} from '../../utils/radar/data';
import { Header } from '@backstage/core-components';
import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';
import { LinearProgress, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreVert';
import { useAppSelector } from '../../hooks/reduxhooks';
import { calculateItemPositions } from './positions';

interface itemProps {
  id: string;
  release?: string;
  title: string;
  ring: string;
  quadrant: string;
  body?: string;
  featured?: boolean;
  flag?: string;
  tags: string[];
  position?: number[];
}


const tags = getTags();
const useStyles = makeStyles({
  menuCard: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'white',
    width: '150px',
    textAlign:'center',
    marginTop: '-76px',
    color: 'grey',
    right: '25px',
    
  },
  list:{
    cursor:'pointer'
  },
  button:{
    cursor:'pointer',
    fontSize:'15px',
    border:'none',
    backgroundColor:'transparent'
  },
  menuIcon: {
    position: 'absolute',
    right: 40,
    marginTop: '-50px',
    zIndex: 999,
    color: 'white',
  },
});
const size = 800;
const rings = getRings();

const TechRadarHome = () => {
  const classes = useStyles();
  const chartConfig = getChartConfig();
  const sections = getSections();

  const [selectedTag, setSelectedTag] = useState('');
  const [isViewOpen, setIsViewOpen] = React.useState(false);
  const {techRadarArr, loading}=useAppSelector((state)=>state.techRadar)
  const [aoeTechRadarList, setAoeTechRadarList] = React.useState<itemProps[]>(
    []
  );
  useEffect(()=>{
    setAoeTechRadarList(techRadarArr)
  },[techRadarArr])



  const tagList = new Set();
  const quadrantList = new Set();

  techRadarArr.forEach(item => {
    if (item?.tags?.length > 1) {
      item.tags.forEach((tag_: string) => tagList.add(tag_.toLowerCase()));
    }
    if (item.quadrant) {
      quadrantList.add(item.quadrant);
    }
  });

  const uniqueTags = Array.from(tagList).filter((item, index, self) => {
    return self.findIndex(item_ => item_ === item) === index;
  });
  

 const requiredTags = getQuadrants().filter(x=>x.title)


  const modifiedQuadrants = getQuadrants();

  const missingTags = requiredTags.filter(
    keyword => !modifiedQuadrants.some(item => item === keyword),
  );

  missingTags.forEach(missing => {
    const tagToAdd = getQuadrants().find(item => item === missing);
    if (tagToAdd) {
      modifiedQuadrants.push(tagToAdd);
    }
  });

  if (modifiedQuadrants.length < 4) {
    const additionalTags = getQuadrants().filter(
      tag => !modifiedQuadrants.some(item => item.title === tag.title),
    );

    additionalTags.slice(0, 4 - modifiedQuadrants.length).forEach(tag => {
      modifiedQuadrants.push(tag);
    });
  }
  const updatedItems = useMemo(() => {
    
    return calculateItemPositions(aoeTechRadarList as any, getQuadrants(), rings as any, size);
  }, [aoeTechRadarList])

  if (loading && techRadarArr.length < 1) {
    return <LinearProgress />;
  }
  const handleViewSource = async() => {
    setIsViewOpen(e => !e);
    const location = (await fetchData(
      urls.getConfigByName.concat('newtechRadar.location'),
    )) as string;
    return window.open(location, '_blank');
  };
  const handleViewReadMe = async() => {
    setIsViewOpen(e => !e);
    const location = (await fetchData(
      urls.getConfigByName.concat('newtechRadar.readme'),
    )) as string;
    return window.open(location, '_blank');
  };
  const ViewCard = () => {
    return (
      <div className={classes.menuCard}>
        <ul style={{listStyle:'none',padding:'5px'}}>
          <li className={classes.list}><button className={classes.button} onClick={handleViewSource} onKeyDown={handleViewSource}>
    View Source
  </button></li>
          <li style={{marginTop:'20px'}} className={classes.list}><button className={classes.button} onClick={handleViewReadMe} onKeyDown={handleViewReadMe}>
    View ReadMe
  </button></li>
        </ul>
        
      </div>
    );
  };
  function handleSelectedTag(tag: string) {
    if (tag === selectedTag) {
      setSelectedTag('');
      setAoeTechRadarList(techRadarArr);
      return;
    }
    setSelectedTag(tag);
    if (tag) {
      setAoeTechRadarList([]);
      const selectedTagList = techRadarArr.filter(x =>
        x.tags.includes(tag.toLowerCase()),
      );
      setAoeTechRadarList(selectedTagList);
    }
  }

  return (
    <>
      <Header
        title="Tech Radar"
        subtitle="Pick the recommended technologies for your projects"
      />
      <section className={classes.menuIcon}>
        <MenuIcon onClick={() => setIsViewOpen(e=>!e)} />
        {isViewOpen && <ViewCard />}
      </section>
      <div
        style={{
          width: '97%',
          marginLeft: '10px',
          marginTop: '20px',
          maxHeight: '100vh',
          margin: '20px auto 0 auto',
        }}
      >
        {sections.map(section => {
          switch (section) {
            case 'radar':
              return (
                <Radar
                  key={section}
                  size={chartConfig.size}
                  quadrants={modifiedQuadrants as any}
                  rings={rings}
                  items={updatedItems as any}
                />
              );
            case 'tags':
              return (
                tags?.length > 0 && (
                  <Tags
                    key={section}
                    tags={uniqueTags as string[]}
                    handleSelectedTag={handleSelectedTag}
                    activeTag={selectedTag}
                  />
                )
              );
            case 'list':
              return (
                <QuadrantList
                  key={section}
                  type='default'
                  items={
                    updatedItems.filter((item, index, self) => {
                      return (
                        self.findIndex(item_ => item_.title === item.title) ===
                        index
                      );
                    }) as any
                  }
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </>
  );
};

export default TechRadarHome;
