
import {  createSlice } from '@reduxjs/toolkit';


export interface groupInfoArrProps {
  groupInfoArr: any[]; 
  loading: boolean;
  error: string | null;
}

const initialState: groupInfoArrProps = {
  groupInfoArr: [],
  loading: false,
  error: null,
};


const groupInfoSlice = createSlice({
  name: 'groupInfo',
  initialState,
  reducers: {
    setCustomGroupInfo:(state,action)=>{
      state.groupInfoArr=action.payload
    }
  },
 
});


export const { setCustomGroupInfo } = groupInfoSlice.actions; 
export default groupInfoSlice.reducer;
