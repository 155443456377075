import { Sidebar, SidebarDivider, SidebarGroup, SidebarItem, SidebarPage, SidebarScrollWrapper, SidebarSpace } from '@backstage/core-components';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { Settings as SidebarSettings, UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import BuildIcon from '@material-ui/icons/Build';
import CategoryIcon from '@material-ui/icons/Category';
import ExtensionIcon from '@material-ui/icons/Extension';
import ReportIcon from '@material-ui/icons/Report';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import MenuIcon from '@material-ui/icons/Menu';
import MapIcon from '@material-ui/icons/MyLocation';
import SearchIcon from '@material-ui/icons/Search';
import GuardRailIcon from '@material-ui/icons/Security';
import GuidelinesIcon from '@material-ui/icons/Description';
import React, { PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LogoDigitalHangar from '../../assets/LHG_Digital_Hangar_logo.svg';

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const [hoveredItem, setHoveredItem] = useState('');
  const location =useLocation();

  const color = '#05164d';
  const handleMouseOver = (url: string) => {
    setHoveredItem(url.toLowerCase());
  };

  const handleMouseOut = () => {
    setHoveredItem('');
  };



  return (
    <SidebarPage>
      <Sidebar>
        <img src={LogoDigitalHangar} alt="logo" style={{ width: '220px' }} />
        <SidebarGroup
          label="Search"
          icon={<SearchIcon />}
          to="/search"
          style={{
            color:
              hoveredItem === 'search' && location.pathname === '/search'
                ? color
                : '',
          }}
          onMouseLeave={handleMouseOut}
          onBlur={handleMouseOut}
          onFocus={handleMouseOut}
          onClick={() => handleMouseOver('search')}
          onMouseOver={() => handleMouseOver('search')}
        >
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem
            icon={HomeIcon}
            to="/"
            text="Home"
            style={{
              color:
                hoveredItem === '/' && location.pathname === '/' ? color : '',
            }}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onFocus={handleMouseOut}
            onClick={() => handleMouseOver('/')}
            onMouseOver={() => handleMouseOver('/')}
          />
          <SidebarItem
            icon={CategoryIcon}
            to="catalog"
            text="Catalog"
            style={{
              color:
                hoveredItem === 'catalog' &&
                location.pathname.split('/')[1] === 'catalog'
                  ? color
                  : '',
            }}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onFocus={handleMouseOut}
            onClick={() => handleMouseOver('Catalog')}
            onMouseOver={() => handleMouseOver('Catalog')}
          />
          <SidebarItem
            icon={ExtensionIcon}
            to="api-docs"
            text="APIs"
            style={{
              color:
                hoveredItem === 'api-docs' &&
                location.pathname.split('/')[1] === 'api-docs'
                  ? color
                  : '',
            }}
            onMouseOut={handleMouseOut}
            onFocus={handleMouseOut}
            onBlur={handleMouseOut}
            onClick={() => handleMouseOver('api-docs')}
            onMouseOver={() => handleMouseOver('api-docs')}
          />
          <SidebarItem
            icon={LibraryBooks}
            to="docs"
            text="Docs"
            style={{
              color:
                hoveredItem === 'docs' &&
                location.pathname.split('/')[1] === 'docs'
                  ? color
                  : '',
            }}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onFocus={handleMouseOut}
            onClick={() => handleMouseOver('Docs')}
            onMouseOver={() => handleMouseOver('Docs')}
          />
         
          <SidebarItem
            icon={AccountTreeIcon}
            to="graph"
            text="Graph"
            style={{
              color:
                hoveredItem === 'graph' &&
                location.pathname.split('/')[1] === 'graph'
                  ? color
                  : '',
            }}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onFocus={handleMouseOut}
            onClick={() => handleMouseOver('graph')}
            onMouseOver={() => handleMouseOver('graph')}
          />
          <SidebarItem
            icon={BuildIcon}
            to="entity-validation"
            text="Validator"
            style={{
              color:
                hoveredItem === 'entity-validation' &&
                location.pathname.split('/')[1] === 'entity-validation'
                  ? color
                  : '',
            }}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onFocus={handleMouseOut}
            onClick={() => handleMouseOver('entity-validation')}
            onMouseOver={() => handleMouseOver('entity-validation')}
          />
           <SidebarItem
            icon={ReportIcon}
            to="report"
            text="Report"
            
            style={{
              color: (hoveredItem === 'report' && location.pathname.split('/')[1]==='report') ? color : '',
            }}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onFocus={handleMouseOut}
            onClick={()=>handleMouseOver('report')}
            onMouseOver={() => handleMouseOver('report')}
          />
          <SidebarItem
            icon={CreateComponentIcon}
            to="create"
            text="Create..."
            style={{
              color:
                hoveredItem === 'create' &&
                location.pathname.split('/')[1] === 'create'
                  ? color
                  : '',
            }}
            onMouseOut={handleMouseOut}
            onFocus={handleMouseOut}
            onBlur={handleMouseOut}
            onClick={() => handleMouseOver('create')}
            onMouseOver={() => handleMouseOver('create')}
          />
          <SidebarItem
            icon={GuidelinesIcon}
            to="guidelines"
            text="Guidelines"
            style={{
              color:
                hoveredItem === 'guidelines' &&
                location.pathname.split('/')[1] === 'Guidelines'
                  ? color
                  : '',
            }}
            onMouseOut={handleMouseOut}
            onFocus={handleMouseOut}
            onBlur={handleMouseOut}
            onClick={() => handleMouseOver('Guidelines')}
            onMouseOver={() => handleMouseOver('Guidelines')}
          />
          <SidebarItem
            icon={GuardRailIcon}
            to="guardrails"
            text="GuardRails"
            style={{
              color:
                hoveredItem === 'guardrails' &&
                location.pathname.split('/')[1] === 'guardrails'
                  ? color
                  : '',
            }}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onFocus={handleMouseOut}
            onClick={() => handleMouseOver('guardrails')}
            onMouseOver={() => handleMouseOver('guardrails')}
          />
         
          <SidebarDivider />
          <SidebarScrollWrapper>
            <SidebarItem
              icon={MapIcon}
              to="techRadar"
              text="Tech Radar"
              style={{
                color:
                  hoveredItem === 'techRadar' &&
                  location.pathname.split('/')[1] === 'techRadar'
                    ? color
                    : '',
              }}
              onMouseOut={handleMouseOut}
              onBlur={handleMouseOut}
              onFocus={handleMouseOut}
              onClick={() => handleMouseOver('techRadar')}
              onMouseOver={() => handleMouseOver('techRadar')}
            />
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
          style={{
            color: hoveredItem === 'settings' ? color : '',
          }}
          onMouseOut={handleMouseOut}
          onBlur={handleMouseOut}
          onFocus={handleMouseOut}
          onClick={() => handleMouseOver('settings')}
          onMouseOver={() => handleMouseOver('settings')}
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};

