import { RELATION_API_CONSUMED_BY, RELATION_API_PROVIDED_BY, RELATION_CONSUMES_API, RELATION_DEPENDENCY_OF, RELATION_DEPENDS_ON, RELATION_HAS_PART, RELATION_PART_OF, RELATION_PROVIDES_API } from '@backstage/catalog-model';
import { FeatureFlagged } from '@backstage/core-app-api';
import { isAdrAvailable } from '@backstage-community/plugin-adr';
import { EntityLayout, EntityLinksCard } from '@backstage/plugin-catalog';
import { Direction, EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityDomainPageAboutCard } from '@internal/plugin-domain-page-about-card';
import { Grid } from '@material-ui/core';
import React from 'react';
import CustomizedSystemPage from '../../../pages/CustomizedSystemPage'
import ADRList from '../../../pages/Adr';
import { CustomDomainEntityLayout } from '../../../pages/EntityLayout/CustomDomainEntityLayout';
import { techdocsContent } from '../content/techdocsContent';

export const myCustomFilterFn = (path: string): boolean => {
  if (path === 'template.md' || path === 'index.md' || path === 'README.md') {
    return false;
  }
  // Match all files following the pattern title-with-dashes.md
  return /^.+\.md$/.test(path);
};
export const systemPage = (entityWarningContent: JSX.Element) => {
  return (
    <CustomDomainEntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {entityWarningContent}
          <Grid item md={6} xs={12}>
            <FeatureFlagged with="about-card">
              <EntityDomainPageAboutCard variant="gridItem" />
            </FeatureFlagged>

            <FeatureFlagged without="about-card">
              <EntityDomainPageAboutCard variant="gridItem" />
            </FeatureFlagged>
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={12} xs={12}>
            <EntityLinksCard />
          </Grid>
          <Grid item md={12}>
            <CustomizedSystemPage type='Component'/>
          </Grid>

          <Grid item md={12}>
          <CustomizedSystemPage type='api'/>
          </Grid>
          <Grid item md={12}>
          <CustomizedSystemPage type='Resource'/>
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>
      <EntityLayout.Route if={isAdrAvailable} path="/adr" title="ADR">
        <ADRList />
      </EntityLayout.Route>
      <EntityLayout.Route path="/diagram" title="Diagram">
        <EntityCatalogGraphCard
          variant="gridItem"
          direction={Direction.TOP_BOTTOM}
          title="System Diagram"
          height={700}
          relations={[
            RELATION_PART_OF,
            RELATION_HAS_PART,
            RELATION_API_CONSUMED_BY,
            RELATION_API_PROVIDED_BY,
            RELATION_CONSUMES_API,
            RELATION_PROVIDES_API,
            RELATION_DEPENDENCY_OF,
            RELATION_DEPENDS_ON,
          ]}
          unidirectional={false}
        />
      </EntityLayout.Route>
    </CustomDomainEntityLayout>
  );
};
