import { Content, Header, Page } from '@backstage/core-components';
import { fetchData, urls } from '@internal/backstage-plugin-dh-dock-shared-common';
import { Card } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FC, useEffect, useState } from 'react';
import React from 'react';
import GuidelinesPage from './Guidelines';


const GuideLines:FC<{from?:string}> = ({from}) => {

  const [guideLineList, setGuideLinesList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const getGuideLinesById = async () => {
      try {
        const getLocationData = await fetchData(
          `api/catalog/entities/by-name/domain/default/cop_dso`,
        );
        const url=getLocationData?.metadata.annotations!!["backstage.io/guideline-location"] as string
        const response = await fetchData(
         `${urls.getAdrList}${url}`,
        );
        const guidelineFile=response?.data.find((x:{name:string})=>x.name==='Guidelines.json');
        const getContentt=await fetchData(`${urls.getAdrContentByName}${url.concat(guidelineFile?.name)}`)
        
        setGuideLinesList(JSON.parse(getContentt?.data) as Array<string>);
        setIsLoading(false);
      } catch (error) {
        setGuideLinesList([]);
        setIsLoading(false);
      }
    };
    getGuideLinesById();
  }, []);


  return <div><Card data-testid="entity-group-list">
      {from ? <Page themeId="tool">
          <Header title="GuideLines" subtitle="A list of all the guidelines" />
          {isLoading && <LinearProgress/>}
          <Content>
            <GuidelinesPage guideLineList={guideLineList}/>
          </Content>

      </Page>:<GuidelinesPage guideLineList={guideLineList}/> }
     
    </Card></div>
  

};

export default GuideLines;
