import { EmptyState } from '@backstage/core-components';
import { EntityRefLink, useEntity } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useAppSelector } from '../../hooks/reduxhooks';
import { OwnershipDetailsProps, dhDocktype } from './types';

export function parseDetails(rawString: string) {
  // Extract parts using regex or string manipulation
  const parts = rawString?.split(', Spec: ');
  const nameDesc = parts[0]?.split(', Description: ');

  const parsedObject: OwnershipDetailsProps = {
    name: nameDesc[0]?.replace('Name: ', '').trim(),
    description: nameDesc[1]?.trim(),
    spec: JSON.parse(parts[1]), // Convert the JSON part into an object
  };
  return parsedObject;
}

const useCustomSystemStyles = makeStyles({
  customText: {
    wordBreak: 'break-word',
    width: '15em',
  },
});

const CustomizedSystemPage: FC<{ type: dhDocktype }> = ({ type }) => {
  const classes = useCustomSystemStyles();
  const { entity } = useEntity();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { owners } = useAppSelector(state => state.ownersProps);

  const mainOwner = window?.location?.pathname?.split('/')[4]?.toLowerCase();
  let ownerDetails: any[] = [];
  owners
    ?.filter(x => {
      const entityOwners = entity.spec?.owner
        ?.toString()
        .toLowerCase()
        .split(' ')
        .map(item => item.trim());

      const ownerList = x?.owners
        ?.split(' ')
        .map((item: string) => item.trim().toLowerCase());

      if (x?.owners) {
        // Parse the details once we've confirmed they're valid
        const parsedDetails_ = parseDetails(x?.details);
        // Check if parsed system matches the mainOwner
        if (
          parsedDetails_?.spec?.system?.toLowerCase() ===
          mainOwner?.toLowerCase()
        ) {
          ownerDetails.push(x); 
        }
      }

      return (
        ownerList.includes(
          entity.spec?.owner?.toString().toLowerCase().trim(),
        ) || entityOwners!!.some(owner => ownerList.includes(owner))
      );
    })
    .filter(x =>
      x.kind === type.toLocaleLowerCase() && type === 'Component'
        ? x.component_count > 0
        : type === 'api'
          ? x.api_count > 0
          : x.resource_count > 0,
    );

  // Handle page change
  const handleChangePage = (event: any, newPage: number) => {
    event.preventDefault();
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const paginatedData = ownerDetails
    .filter(
      x => x.entity_ref.split(':')[0].toLowerCase() === type.toLowerCase(),
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Card data-testid="entity-has-systems-card">
      <CardHeader
        title={type === 'api' ? type.toUpperCase() : type.concat('s')}
      />
      <CardContent>
        {paginatedData.length ? (
          <>
            <Table aria-label="group list" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>NAME</TableCell>
                  <TableCell>OWNER</TableCell>
                  <TableCell>TYPE</TableCell>
                  <TableCell>LIFECYCLE</TableCell>
                  <TableCell>DESCRIPTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row: any, i: number) => {
                  const parsedData = parseDetails(row.details);
                  return (
                    <TableRow key={row?.metadata?.name + i}>
                      <TableCell style={{ width: '10em' }}>
                        <EntityRefLink entityRef={row?.entity_ref}>
                          <b>{parsedData?.name?.replaceAll('-', ' ')}</b>
                        </EntityRefLink>
                      </TableCell>
                      <TableCell className={classes.customText}>
                        <a
                          style={{ color: '#05164d' }}
                          href={`/catalog/default/group/${row?.owners}`}
                        >
                          {row?.owners}
                        </a>
                      </TableCell>
                      <TableCell className={classes.customText}>
                        {parsedData?.spec?.type}
                      </TableCell>
                      <TableCell className={classes.customText}>
                        {parsedData?.spec?.lifecycle}
                      </TableCell>
                      <TableCell className={classes.customText}>
                        {parsedData?.description}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={
                ownerDetails.filter(
                  x =>
                    x.entity_ref.split(':')[0].toLowerCase() ===
                    type.toLowerCase(),
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <EmptyState
            missing="data"
            title={`This Product has no ${type === 'api' ? type.toUpperCase() : type.concat('s')}`}
          />
        )}
      </CardContent>
    </Card>
  );
};
export default CustomizedSystemPage;
