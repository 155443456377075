import styles from './QuadrantList.module.css';

import { RingList } from '../RingList/RingList';
import { getQuadrant, groupItemsByQuadrant } from '../../../utils/radar/data';
import { Item } from '../../../utils/radar/types';
import React from 'react';

interface RingListProps {
  items: Item[];
  type:"overview"|"default",
  description?: string
}

export function QuadrantList({ items,type="default",description }: RingListProps) {
  const quadrants = groupItemsByQuadrant(items);
  return (
    <ul className={styles.quadrants}>
      {Object.entries(quadrants).map(([quadrantId, items]) => {
        const quadrant = getQuadrant(quadrantId);
        if (!quadrantId) return null;
        return (
          <li key={quadrantId} className={styles.quadrant}>
            <div className={styles.header}>
              <h1 className={styles.title}>{quadrant?.title}</h1>
            </div>
            <h2>{description}</h2>

           {type==='default' && <hr style={{marginTop:'-20px',marginBottom:'20px'}}/>}

            <RingList items={items} size="default" />
          </li>
        );
      })}
    </ul>
  );
}
