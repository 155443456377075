import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { FC } from "react";



const GuidelinesPage:FC<{guideLineList:string[]}>=({guideLineList})=>{
    return <>
    {Object.entries(guideLineList).sort((a,b)=>{
      return a[0].localeCompare(b[1])
    }).length > 0 && (
      <Table aria-label="group list" size="small">
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(guideLineList).map(item => (
            <TableRow key={item[0]}>
              <TableCell>
              <b><a href={item[1]} target="_blank" rel="noopener noreferrer">{item[0]}</a></b>

              </TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )}
  </>
}

export default GuidelinesPage