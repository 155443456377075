
import { ComponentPropsWithoutRef } from "react";

import styles from "./Tags.module.css";

import IconRemove from "../Icons/Close";
import IconTag from "../Icons/Tag";
import { getLabel } from "../../../utils/radar/data";
import { cn } from "../../../utils";
import { LinkProps } from "react-router-dom";
import React from "react";

type TagProps = {
  tag: string;
  handleSelectedTag?:(tag:string)=>void
  isActive?: boolean;
} & Omit<LinkProps, "href"> &
  ComponentPropsWithoutRef<"a">;

export function Tag({ tag, isActive, className,handleSelectedTag}: TagProps) {
  const Icon = isActive ? IconRemove : IconTag;
  return (
    <div
      className={cn(styles.tag, className, isActive && styles.active)}
      onClick={()=>handleSelectedTag?.(tag)}
    >
      <Icon className={cn(styles.icon)}  />
      <span className={styles.label}>{tag}</span>
    </div>
  );
}

interface TagsProps {
  tags: string[];
  activeTag?: string;
  className?: string;
  handleSelectedTag?:(tag:string)=>void
}

export function Tags({ tags, activeTag, className,handleSelectedTag }: TagsProps) {
  const label = getLabel("filterByTag");
  return (
    <div className={cn(styles.tags, className)}>
      {!!label && <h3>{label}</h3>}
      {tags.map((tag) => (
        <Tag key={tag} tag={tag} isActive={activeTag == tag} to={""} handleSelectedTag={handleSelectedTag}   />
      ))}
    </div>
  );
}
